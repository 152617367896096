import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import bg_hub from "../Assets/Images/bg_hub.png";
import community_1 from "../Assets/Images/community_1.png";
import community_2 from "../Assets/Images/community_2.png";
import bottom from "../Assets/Images/bottom.svg";
import CustomButton from "./CustomButton";

const CommunityHub = () => {
  return (
    <Box
      sx={{
        position: "relative",
        pt: "1.5rem",
        "& .main_box": {
          transition: "0.3s all ease",
          "&:hover .front_box": {
            display: "none",
          },
          "&:hover .back_box": {
            display: "block",
          },
          "& .back_box": {
            borderRadius: "5px",
            display: "none",
          },
        },
      }}
    >
      <Typography
        component={"img"}
        src={bottom}
        sx={{
          position: "absolute",
          top: "-50%",
          left: "40%",
          transform: "translate(-40%, 50%)",
          // width: "100%",
        }}
      />
      <Grid container spacing={2} position={"relative"}>
        <Grid item lg={6} md={6} sm={12} xs={12} className="main_box">
          <Box
            className="front_box"
            sx={{
              backgroundImage: `url(${bg_hub})`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "100%",
              py: "6rem",
              height: { lg: "370px" },
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(0 0 0 / 15%)",
                backdropFilter: "blur(2px)",
                py: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "96px", xs: "40px" },
                  lineHeight: { md: "1", xs: "1" },
                  fontFamily: "latoBold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                TokenTalk
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "40px", xs: "20px" },
                  fontFamily: "lato-light",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Join the Community
              </Typography>
            </Box>
          </Box>
          <Box
            className="back_box"
            sx={{
              backgroundImage: `url(${bg_hub})`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "100%",
              height: { lg: "370px" },
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(0 0 0 / 15%)",
                backdropFilter: "blur(15px)",
                p: "2rem 15px",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "48px", xs: "28px" },
                  fontFamily: "latoBold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                TokenTalk
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "36px", xs: "20px" },
                  fontFamily: "lato-light",
                  color: "#fff",
                  textAlign: "center",
                  pb: "1rem",
                }}
              >
                Join the Community
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "40px", xs: "28px" },
                  lineHeight: "1.2",
                  fontFamily: "latoBold",
                  fontWeight: "600",
                  color: "#7E2EA4",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Our community always welcomes you!
              </Typography>
              <Box pt={"1.2rem"} textAlign="center">
                <CustomButton text={"Join our Community"} width={"50%"} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          alignItems={"center"}
          display={"flex"}
        >
          <Box>
            <Grid container>
              <Grid
                item
                lg={9}
                md={9}
                sm={12}
                xs={12}
                alignItems={"center"}
                display={"flex"}
              >
                <Box
                  sx={{
                    p: { lg: "15px", xs: "15px" },
                    border: "1px solid #45125E",
                    borderRadius: "4px",
                    paddingRight: { lg: "5% !important" },
                    textAlign: "center",
                  }}
                  bgcolor={"rgb(255 255 255 / 7%)"}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "lato",
                      color: "#fff",
                    }}
                  >
                    Meet <b>JohnCrypto</b>, one of our most active and
                    knowledgeable member in the community for over a year and
                    made significant contributions through his insightful...
                  </Typography>
                  <Box mt={"0.6rem"} textAlign="center">
                    <CustomButton text={"Know more"} width={"50%"} />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} lineHeight={0}>
                <Typography
                  component={"img"}
                  src={community_1}
                  sx={{
                    width: { lg: "110%", xs: "100%" },
                    height: "100%",
                    marginLeft: { lg: "-10%", xs: "0" },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container mt={"1rem"}>
              <Grid
                item
                lg={9}
                md={9}
                sm={12}
                xs={12}
                alignItems={"center"}
                display={"flex"}
              >
                <Box
                  sx={{
                    p: { lg: "15px", xs: "15px" },
                    border: "1px solid #45125E",
                    borderRadius: "4px",
                    paddingRight: { lg: "5% !important" },
                    textAlign: "center",
                  }}
                  bgcolor={"rgb(255 255 255 / 7%)"}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "lato",
                      color: "#fff",
                    }}
                  >
                    Meet <b>JohnCrypto</b>, one of our most active and
                    knowledgeable member in the community for over a year and
                    made significant contributions through his insightful...
                  </Typography>
                  <Box mt={"0.6rem"} textAlign="center">
                    <CustomButton text={"Know more"} width={"50%"} />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                lineHeight={0}
                position={"relative"}
              >
                <Typography
                  component={"img"}
                  src={community_2}
                  sx={{
                    width: { lg: "110%", xs: "100%" },
                    height: "100%",
                    borderRadius: "8px",
                    marginLeft: { lg: "-10%", xs: "0" },
                  }}
                />
                <Box
                  sx={{
                    borderRadius: "8px",
                    background:
                      "linear-gradient(270deg, rgba(126,46,164,0.4) 0%, rgba(75,29,97,0.1) 48%, rgba(126,46,164,0.4) 100%)",
                    position: "absolute",
                    width: { lg: "110%", xs: "100%" },
                    marginLeft: { lg: "-10%", xs: "0" },
                    height: "100%",
                    zIndex: "1",
                    top: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // boxShadow: "inset 10px 10px 20px rgba(0, 0, 0, 0.5), inset -10px -10px 20px rgba(0, 0, 0, 0.5)"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "40px",
                      lineHeight: "1",
                      fontFamily: "latoBold",
                      color: "#fff",
                    }}
                  >
                    25 <br />{" "}
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontFamily: "lato",
                        color: "#fff",
                      }}
                    >
                      April
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommunityHub;
