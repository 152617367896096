import {
  Box,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import create_coin from "../Assets/Images/create_coin.gif";
import * as Yup from "yup";
import axios from "axios";
import {
  useAccount,
  useBalance,
  useChainId,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { API_URL, POOL_ADDRESS } from "../Config";
import TOKEN_COIN_ABI from "../Config/TOKEN_COIN_ABI.json";
import { ContractContext } from "../Context/ContractContext";
import { useNavigate } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { erc20Abi, formatEther, parseEther } from "viem";
import Chains from "../Components/Chains";
import AmountModal from "../Components/AmountModal";

const CreateCoinForm = () => {
  const { isConnected, address,connector} = useAccount();
  const { data: balance, queryKey: balanceQueryKey } = useBalance({
    address: address,
  });
  const chainId = useChainId();
  const { getCoin } = useContext(ContractContext);
  const getInitialValues = () => {
    return {
      name: "",
      code: "",
      twitter: "",
      telegram: "",
      website: "",
      description: "",
      // decimal: "",
      token: false,
    };
  };
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState('');
  const [imageErr, setImageErr] = useState("");
  const [balErr, setBalErr] = useState("");
  const [id, setId] = useState("");
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim("Name should not contain spaces")
      .required("Required"),
    code: Yup.string()
      .trim("Code should not contain spaces")
      .required("Required"),
    twitter: Yup.string()
      .trim("Link should not contain spaces")
      ,
    telegram: Yup.string()
      .trim("Link should not contain spaces")
      ,
    website: Yup.string()
      .trim("Link should not contain spaces")
      ,
    description: Yup.string()
      .trim("Link should not contain spaces")
      ,
    // decimal: Yup.number().required("Required"),
    token: Yup.string().required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      if (image == null) {
        setImageErr("Please select image");
        return
      } 
      setAmountModal(true)
    },
  });

  const handleImage = (e) => {
    setImageErr("");
    setImage(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  const [loading, setLoading] = useState(false);

  const { data: coinFee } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getDeploymentFee",
    args: [],
  });
// console.log(coinFee);
  const createCoin = async () => {
    setAmountModal(false)
    setBalErr("")
    if(formatEther(balance?.value)<formatEther(coinFee || 0)){
      setBalErr("Insufficient balance");
      return;
    }
    
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", formik.values.name);
      formData.append("desc", formik.values.description);
      formData.append("symbol", formik.values.code);
      // formData.append("decimal", "18");
      formData.append("twitterLink", formik.values.twitter);
      formData.append("websiteLink", formik.values.website);
      formData.append("telegramLink", formik.values.telegram);
      formData.append("isToken", formik.values.token);
      formData.append("image", image); // Assuming 'imageFile' is the file input value
      formData.append("chain", chainId); 
      // formData.append("userId", localStorage.getItem("username")); 

      const res = await axios.post(`${API_URL}/create/token-coin`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": localStorage.getItem("access_token"),
        },
      });

      if (res.status === 201) {
        setLoading(false);
        const { _id, name, symbol, decimal, isToken } = res.data.data;
        setId(_id);
        writeAsync(_id, name, symbol, isToken);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const { data: alternateToken } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "alternateToken",
    args: [],
  });
  const { data: alternateTokenSymbol } = useReadContract({
    abi: erc20Abi,
    address: alternateToken,
    functionName: "symbol",
    args: [],
  });
  
 
  const { writeContractAsync } = useWriteContract();
  const [txn, setTxn] = useState("");
  const [isLoading, setIsloading] = useState("");
  const [amountModal, setAmountModal] = useState(false);
  const [buyValue, setBuyValue] = useState(0);
  const handleOnchange = (e) => {
    // setBuyErr("");
    const value = e.target.value;
    if (value >= 0) {
      setBuyValue(value);
    }
  };
  console.log(coinFee,buyValue);
  const writeAsync = async (_id, _name, _symbol, isToken) => {
    const config = {
      address: POOL_ADDRESS,
      abi: TOKEN_COIN_ABI,
      functionName: "createPool",
      args: [_id, _name, _symbol, isToken,parseEther(buyValue.toString()||0)],
      value: coinFee + parseEther(buyValue.toString()||0),
    };
    setTxn(null);
    try {
      setIsloading(true);
      const txn = await writeContractAsync?.(config);
      setTxn(txn);
    } catch (e) {
      setIsloading(false);
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingWait,
    isSuccess: isSuccessWait,
    data: coinData,
  } = useWaitForTransactionReceipt({
    hash: txn,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccessWait) {
      getCoin();
      formik.resetForm();
      setImage(null);
      navigate(`/nft-detail/${id}`);
    }
  }, [isSuccessWait]);
  return (
    <Box
      sx={{
        my: "3rem",
        "& .MuiFormControl-root": {
          width: "100%",
          border: "1px solid rgb(255 255 255 / 50%) !important",
          borderRadius: "10px ",
          background: "#211f1f",
          color: "#fff",
          "&:focus-Visible": {
            outline: "none !important",
            border: "none !important",
          },
          "&:hover": {
            border: "none",
          },

          "&:focus": {
            boxShadow: "none !important",
            border: "2px solid #222222 !important",
            borderRadius: "0px !important ",
          },
          "& .MuiInputBase-root": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
          },
          "& input": {
            padding: "10px",
            fontSize: "20px",
            borderRadius: "10px",
            fontWeight: "500",
            fontFamily: "lato !important",
            color: "rgb(255 255 255 / 80%)",
            "&::placeholder": {
              color: "#fff !important",
              fontFamily: "lato",
              fontSize: "16px",
            },
          },
          "& textarea": {
            // background: "#F7F7F9",
            padding: "10px !important",
            color: "#fff",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              color: "#fff",
            },
          },
        },
        "& .error": {
          marginTop: "0.5rem !important",
          fontSize: "14px !important",
          color: "red !important",
          textAlign: "start",
        },
        "& .label": {
          marginBottom: "0.1rem",
          fontFamily: "latoBold",
          fontSize: "16px",
          color: "#fff",
          textAlign: "start",
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: { lg: "55%", sm: "75%", xs: "100%" },
            margin: "0 auto 3rem",
          }}
        >
        {/* <Chains/> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap:"wrap"
            }}
          >
            <Typography className="label">Image</Typography>
            <Typography
              className="label"
              style={{ color: "rgb(255 255 255 / 50%)" }}
            >
              Cost to deploy: {formatEther(coinFee || 0)?.toString()}{" "}
              {balance?.symbol}
            </Typography>
          </Box>
          <Box
            sx={{
              p: "2rem",
              border: "1px dashed #7E2EA4",
              borderRadius: "10px",
              mb: "1rem",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                color: "rgb(255 255 255 / 50%)",
                fontFamily: "lato",
                fontSize: "16px",
              }}
            >
              PNG, JPG, GIF, WEBP,
            </Typography>
            <Button
              sx={{
                mt: "1rem",

                border: "1px solid #7E2EA4",
                background: "#45125E",
                p: "0.5rem 1.5rem",
                // width:{md:"200px",xs:"100%"},
                textTransform: "capitalize",
                fontFamily: "latoBold",
                "&:hover": {
                  background: "#632283",
                },
              }}
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Choose File
              <input
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
                onChange={handleImage}
                type="file"
              />
            </Button>
            <Typography pt={".2rem"}>{image?.name}</Typography>
            {imageErr && (
              <Typography variant="body1" className="error">
                {imageErr}
              </Typography>
            )}
            {preview && (
        <Box mt="1rem">
          <img src={preview} alt="Preview" style={{ width: '200px',height:"200px", borderRadius: '10px' }} />
        </Box>
      )}
          </Box>
          <Grid container spacing={2} sx={{}}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Name</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                name="name"
                type="text"
                // className={classes.bin1}
                placeholder="Enter Your Name"
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <Typography variant="body1" className="error">
                  {formik.errors.name}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Code</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="code"
                name="code"
                type="text"
                // className={classes.bin1}
                placeholder="Enter Your Code"
                variant="standard"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.code && formik.errors.code && (
                <Typography variant="body1" className="error">
                  {formik.errors.code}
                </Typography>
              )}
            </Grid>
            {/* <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Decimal</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="decimal"
                name="decimal"
                type="text"
                // className={classes.bin1}
                placeholder="Enter decimal"
                variant="standard"
                value={formik.values.decimal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.decimal && formik.errors.decimal && (
                <Typography variant="body1" className="error">
                  {formik.errors.decimal}
                </Typography>
              )}
            </Grid> */}
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                "& option": {
                  background: "#fff",
                  fontSize: "16px",
                  border: "1px solid #000",
                },
                "& select": {
                  "&:focus": {
                    border: "none",
                  },
                },
              }}
            >
              <Typography className="label">Token</Typography>
              <FormControl
                className="custom_form"
                fullWidth
                sx={{
                  padding: "2px",
                  borderRadius: "50px",
                  background: "",
                  fontSize: "16px",
                  border: "1px solid #000",
                  color: "#fff !important",
                  mr: "0",

                  "& .MuiInputBase-root::before": {
                    display: "none",
                  },
                  "& .MuiInput-root:hover::before": {
                    borderBottom: "0",
                  },
                  "& .MuiInputBase-root::after": {
                    borderBottom: "0",
                  },
                  "& select": {
                    p: "10px",
                    pl: "15px",
                    color: "#fff",
                  },
                  "& option": {
                    padding: "5px !important",
                    color: "#fff",
                  },
                  "& svg": {
                    color: "#fff",
                  },
                  "& .MuiNativeSelect-select": {
                    backgroundColor: "#211f1f !important",
                  },
                }}
              >
                <NativeSelect
                  value={formik.values.token}
                  id="token"
                  name="token"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="custom_select"
                >
                  <option value={""}>--select--</option>
                  <option value={false}>{balance?.symbol||"TBNB"}</option>
                  <option value={true}>
                    {alternateTokenSymbol}
                  </option>
                </NativeSelect>
              </FormControl>

              {formik.touched.token && formik.errors.token && (
                <Typography variant="body1" className="error">
                  {formik.errors.token}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Twitter link</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="twitter"
                name="twitter"
                type="text"
                // className={classes.bin1}
                placeholder="Enter Your twitter link"
                variant="standard"
                value={formik.values.twitter}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.twitter && formik.errors.twitter && (
                <Typography variant="body1" className="error">
                  {formik.errors.twitter}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Telegram link</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="telegram"
                name="telegram"
                type="text"
                // className={classes.bin1}
                placeholder="Enter Your telegram link"
                variant="standard"
                value={formik.values.telegram}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.telegram && formik.errors.telegram && (
                <Typography variant="body1" className="error">
                  {formik.errors.telegram}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Website link</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="website"
                name="website"
                type="text"
                // className={classes.bin1}
                placeholder="Enter Your website link"
                variant="standard"
                value={formik.values.website}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.website && formik.errors.website && (
                <Typography variant="body1" className="error">
                  {formik.errors.website}
                </Typography>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography className="label">Description</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="description"
                name="description"
                type="text"
                // className={classes.bin1}
                placeholder="Description.."
                variant="standard"
                multiline={5}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description && (
                <Typography variant="body1" className="error">
                  {formik.errors.description}
                </Typography>
              )}
              {balErr && (
          <Typography
            sx={{
              pt:"5px",
              fontFamily: "lato",
              fontSize: "15px",
              color: "red",
            }}
          >
            {balErr}
          </Typography>
        )}
            </Grid>
          </Grid>

          <Box
            textAlign={"center"}
            sx={{
              "& div": {
                justifyContent: "center",
              },
            }}
            pt={"1.5rem"}
          >
            {isConnected ? (
              <Button
                //   component={Link}
                type="submit"
                //   to="/create-coin"
                sx={{
                  backgroundImage: `url(${create_coin})`,
                  backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                  backgroundPosition: "100%",
                  backgroundRepeat: "no-repeat",
                  fontSize: "16px",
                  color: "rgba(255, 255, 255, 0.7)",
                  fontFamily: "latoBold",
                  mt: "1rem",
                  transition: "all 0.3s ease",
                  textTransform: "capitalize",
                  width: { sm: "350px", xs: "80%" },
                  p: "10px",
                }}
              >
                {(loading || isLoading || isLoadingWait) && !isSuccessWait
                  ? "Creating..."
                  : "Create a fresh coin!"}
              </Button>
            ) : (
              <ConnectButton label="Connect" />
            )}
          </Box>
        </Box>
      </form>
      <AmountModal buyValue={buyValue} amountModal={amountModal} setAmountModal={setAmountModal} createCoin={createCoin} handleOnchange={handleOnchange}/>
    </Box>
  );
};

export default CreateCoinForm;
