import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import contact_frame from "../Assets/Images/contact_frame.png";
import fb from "../Assets/Images/fb.svg";
import mail from "../Assets/Images/mail.svg";
import phone from "../Assets/Images/phone.svg";
import twitter from "../Assets/Images/twitter.svg";
import discord from "../Assets/Images/discord.svg";
import insta from "../Assets/Images/insta.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../Components/CustomButton";

const Contact = () => {
  const getInitialValues = () => {
    return {
      name: "",
      email: "",
      message: "",
    };
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim("Name should not contain spaces")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .trim("Email should not contain spaces")
      .required("Required"),
    message: Yup.string()
      .trim("Message should not contain spaces")
      .required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
    },
  });
  return (
    <>
      <Container>
        <Navbar />
        <Box
          sx={{
            color: "#fff",
            py: { lg: "9rem", md: "6rem", xs: "2rem" },
            "& .custom_text": {
              fontSize: "32px",
              fontFamily: "latoBold",
              background:
                "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            "& .custom_text_2": {
              fontSize: "16px",
              fontFamily: "lato",
              color: "#AEAEAE",
            },
            "& .MuiFormControl-root": {
              mt: "1rem",
              width: "100%",
              border: "1px solid transparent !important",
              borderRadius: "3px",
              background: "rgb(217 217 217 / 8%)",
              color: "#fff",
              "&:focus-Visible": {
                outline: "none !important",
                border: "none !important",
              },
              "&:hover": {
                border: "none",
              },

              "&:focus": {
                boxShadow: "none !important",
                border: "2px solid transparent !important",
                borderRadius: "0px !important ",
              },
              "& .MuiInputBase-root": {
                "&::before": {
                  display: "none",
                },
                "&::after": {
                  display: "none",
                },
              },
              "& input": {
                padding: "10px",
                fontSize: "20px",
                borderRadius: "10px",
                fontWeight: "500",
                fontFamily: "lato !important",
                color: "#AEAEAE !important",
                "&::placeholder": {
                  color: "#fff !important",
                  fontFamily: "lato",
                  fontSize: "16px",
                },
              },
            },
            "& .error": {
              marginTop: "0.5rem !important",
              fontSize: "14px !important",
              color: "red !important",
              textAlign: "start",
            },
          }}
        >
          <Grid container spacing={5} position={"relative"}>
            <Grid item md={6} sm={12} xs={12}>
              <Box
                sx={{
                  p: { md: "5rem", xs: "3rem 2rem" },
                  backgroundImage: `url(${contact_frame})`,
                  backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: { md: "3rem", xs: "2rem" },

                  "& div": {
                    textAlign: "center",
                  },
                }}
              >
                <Box>
                  <Typography className="custom_text">
                    <Typography component={"img"} src={phone} /> Call us
                  </Typography>
                  <Typography className="custom_text_2">
                    (808) 555-0111
                  </Typography>
                </Box>
                <Box>
                  <Typography className="custom_text">
                    <Typography component={"img"} src={mail} /> Mail us{" "}
                  </Typography>
                  <Typography className="custom_text_2">
                    michelle.rivera@example.com
                  </Typography>
                </Box>
                <Box>
                  <Typography className="custom_text">
                    Connect with us
                  </Typography>
                  <Box
                    sx={{
                      pt: "0.5rem",
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      "& img": {
                        transition: "0.3s all",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.2)",
                        },
                      },
                    }}
                  >
                    <Typography component={"img"} src={fb} />
                    <Typography component={"img"} src={discord} />
                    <Typography component={"img"} src={twitter} />
                    <Typography component={"img"} src={insta} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                className="custom_text"
                sx={{ textAlign: { md: "start", xs: "center" } }}
              >
                Get In Touch
              </Typography>
              <Typography
                className="custom_text_2"
                sx={{ textAlign: { md: "start", xs: "center" } }}
              >
                Feel free to drop us a message!
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <Box pt={"0.5rem"}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    // className={classes.bin1}
                    placeholder="Enter Your Name"
                    variant="standard"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <Typography variant="body1" className="error">
                      {formik.errors.name}
                    </Typography>
                  )}
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    // className={classes.bin1}
                    placeholder="Enter Your email"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className="error">
                      {formik.errors.email}
                    </Typography>
                  )}
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="message"
                    name="message"
                    type="text"
                    // className={classes.bin1}
                    placeholder="Enter Your message"
                    variant="standard"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <Typography variant="body1" className="error">
                      {formik.errors.message}
                    </Typography>
                  )}
                  <Box pt={"1.5rem"}>
                    <CustomButton
                      text={"Submit"}
                      type={"submit"}
                      width={"200px"}
                    />
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{height:"3rem",display:{lg:"block",xs:"none"}}}/> */}
        <Footer />
      </Container>
    </>
  );
};

export default Contact;
