import { Box, Typography } from '@mui/material'
import React from 'react'
import Base from "../Assets/Images/Base.svg";
import BSC from "../Assets/Images/BSC.svg";
import Solana from "../Assets/Images/Solana.svg";
import ton from "../Assets/Images/ton.svg";

const MobileChains = () => {
  return (
    <Box 
    sx={{
      width:"100%",
      zIndex:"2",
  border:"1px solid rgb(126 46 164 / 50%)",
  backdropFilter:"blur(20px)",
  borderRadius:"10px 10px 0 0",
  p:"5px",
  background:"rgba(0, 0, 0, 0.38)",
      display: {  lg: "none",xs: "block" },
      gap: "1.2rem",
      alignItems:"center",
      position:"fixed",
      bottom:"0",
      left:"0"
    }}
  >
  <Box
        sx={{
          "& img": {
            border: "0.5px solid transparent",
            cursor: "pointer",
            transition: "0.3s all ease",
            "&:hover": {
              border: "0.5px solid #a632dd99",
              borderRadius: "3px",
            },
          },
        }}
      >
    
        <Box sx={{
          display:"flex",
          alignItems:"center",
          gap:"0.2rem",
          justifyContent:"center",
          flexWrap:"wrap"
        }}>
          <Typography component={"img"} src={Base} sx={{width:{sm:"100px",xs:"80px"}}}/>
          {/* <Typography component={"img"} src={Blast} /> */}
          <Typography component={"img"} src={BSC} sx={{width:{sm:"auto",xs:"90px"}}}/>
          <Typography component={"img"} src={Solana} sx={{width:{sm:"auto",xs:"100px"}}}/>
          <Typography component={"img"} src={ton}  sx={{width:{sm:"90px",xs:"65px"}}} p={"0.2rem"}/>
        </Box>
      </Box>
  </Box>
  )
}

export default MobileChains