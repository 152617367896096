import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import tEn from "../Languages/en/english.json";
import tDe from "../Languages/de/dutch.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: tEn
      },
      de: {
        translation: tDe
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const ChooseLanguages = () => {
  const [language, setLanguage] = useState(localStorage.getItem("pumpfunlang")?localStorage.getItem("pumpfunlang"):"en");
  const handleLanguage = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem('pumpfunlang', selectedLanguage);

    if (selectedLanguage === "en") {
      i18n.changeLanguage('en');
    } else if (selectedLanguage === "de") {
      i18n.changeLanguage('de');
    }

    setLanguage(selectedLanguage);
  };
useEffect(()=>{
    let currentLang = localStorage.getItem('pumpfunlang')
    i18n.changeLanguage(currentLang)
},[])
  return (
    <FormControl
      sx={{
        minWidth: 150,
        // mb: "2rem",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiInputBase-root": {
          border: "1px solid #800080",
          borderRadius: "5px",
          fontFamily: "ABLE",
        },
        "& .MuiSelect-select": {
          padding: "0.3rem 0.5rem",
          color: "#800080",
          fontFamily: "ABLE",
          fontSize: "17px",
          paddingRight: "2.5rem !important",
        },
        "& .MuiSvgIcon-root": {
          color: "#800080",
          fontFamily: "ABLE",
          right:"2px"
        },
      }}
    >
      <Select
        value={language}
        onChange={handleLanguage}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="en">English</MenuItem>
        {/* <MenuItem value="de">Dutch</MenuItem> */}
      </Select>
    </FormControl>
  );
};

export default ChooseLanguages;
