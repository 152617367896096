import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        fontSize: "1.2rem",
        p: 1.2,
        borderBottom: "2px solid rgba(255, 255, 255, 0.22)",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 6,
            top: 6,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AmountModal = ({
  amountModal,
  setAmountModal,
  createCoin,
  handleOnchange,
  buyValue
}) => {

  return (
    <>
      <Dialog
        onClose={()=>setAmountModal(false)}
        disableScrollLock
        aria-labelledby="customized-dialog-title"
        open={amountModal}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.2rem 1rem",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(2px)",
          },
          "& .MuiPaper-root": {
            // maxWidth: "90% !important",
            maxWidth: "400px !important",
            background: "#000",
            backgroundImage:
              "linear-gradient(305deg, rgb(38 0 252 / 5%), rgb(255 0 234 / 9%))",
            border: "2px solid rgba(255, 255, 255, 0.22) !important",
            backdropFilter: "blur(12.5px)",
            color: "#fff",
            width: { md: "100% !important", xs: "300px !important" },
            overflowX: { md: "auto", xs: "scroll" },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={()=>setAmountModal(false)}
        >
          Enter Buy Amount
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{
               "& .MuiFormControl-root": {
          width: "100%",
          border: "1px solid rgb(255 255 255 / 50%) !important",
          borderRadius: "5px ",
          background: "#211f1f",
          color: "#fff",
          "&:focus-Visible": {
            outline: "none !important",
            border: "none !important",
          },
          "&:hover": {
            border: "none",
          },

          "&:focus": {
            boxShadow: "none !important",
            border: "2px solid #222222 !important",
            borderRadius: "0px !important ",
          },
          "& .MuiInputBase-root": {
            "&::before": {
              display: "none",
            },
            "&::after": {
              display: "none",
            },
          },
          "& input": {
            padding: "7px",
            fontSize: "18px",
            borderRadius: "10px",
            fontWeight: "500",
            fontFamily: "lato !important",
            color: "rgb(255 255 255 / 80%)",
            "&::placeholder": {
              color: "#fff !important",
              fontFamily: "lato",
              fontSize: "16px",
            },
            "&[type=number]": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
            },
          },
        
        },
        "& .error": {
          marginTop: "0.5rem !important",
          fontSize: "14px !important",
          color: "red !important",
          textAlign: "start",
        },
        "& .label": {
          marginBottom: "0.1rem",
          fontFamily: "latoBold",
          fontSize: "16px",
          color: "#fff",
          textAlign: "start",
        },
          }}>
          <Typography className="label">Amount</Typography>
              <TextField
                autoComplete="off"
                fullWidth
                id="description"
                name="description"
                type="number"
                // className={classes.bin1}
                placeholder="Enter Amount.."
                variant="standard"
                onChange={handleOnchange}
                value={buyValue}
                
              />
          </Box>
          <Box textAlign={"center"} py={"0.5rem"}>
            <Button sx={{
                 fontSize: "16px",
                  color: "rgba(255, 255, 255, 0.7)",
                  fontFamily: "latoBold",
                  mt: "1rem",
                  transition: "all 0.3s ease",
                  textTransform: "capitalize",
                //   width: { sm: "350px", xs: "80%" },
                  p: "5px 1rem",
                  border:"1px solid #7E2EA4",
                  background:"#45125E"
            }} onClick={()=>createCoin()}>
            Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AmountModal;
