import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../Config";

export const ContractContext = createContext();
const ContractContextProvider = ({ children }) => {
  const [show, setShow] = useState(true);
  const [coinData, setCoinData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [crownData, setCrownData] = useState({});
  const [selectedChain, setSelectedChain] = useState(0);
  const getCoin = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/token-coins`
    })
      .then((_data) => {
        setCoinData(_data.data.coinsData.reverse());
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getComments = async (projectId) => {
    await axios({
      method: "GET",
      url: `${API_URL}/get/comments/${projectId}`,
    })
      .then((_data) => {
        const reversedArray = _data.data.data.reverse();
        setCommentData(reversedArray);
        getCoin()
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getCrown = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/get/crown`,
      // headers: {
      //   "Accept-Encoding": "application/json",
      //   "x-access-token": localStorage.getItem("access_token"),
      // },
    })
      .then((_data) => {
        setCrownData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };

  
  useEffect(() => {
    getCrown()
    getCoin();
  }, []);
  return (
    <ContractContext.Provider
      value={{
        show,
        setShow,
        coinData,
        getCoin,
        getComments,
        commentData,
        crownData,
        getCrown,
        selectedChain,
        setSelectedChain
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
export default ContractContextProvider;
