import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

class CustomDataFeed {
  onReady(callback) {
    setTimeout(() => callback({
      supports_marks: false,
      supports_timescale_marks: false,
      supports_time: true,
      supported_resolutions: ["1", "5", "15", "30", "60", "1D"]
    }), 0);
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    setTimeout(() => {
      onSymbolResolvedCallback({
        name: symbolName,
        ticker: symbolName,
        session: '24x7',
        timezone: 'Etc/UTC',
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        has_no_volume: false,
        supported_resolutions: ["1", "5", "15", "30", "60", "1D"],
        volume_precision: 2
      });
    }, 0);
  }

  getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
    // fetch(`https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${symbolInfo.ticker}&interval=${resolution}min&apikey=YOUR_ALPHA_VANTAGE_API_KEY`)
    //   .then(response => response.json())
      // .then(data => {
        const bars = [];
        const data = [
          {
            "low": 1,
            "high": 2,
            "open": 2,
            "close": 3,
            'volume': 4
          }
        ]
        for (const time in data ) {
          const bar = [];
          bars.push({
            time: new Date().getTime(),
            low: parseFloat(time['low']),
            high: parseFloat(time['high']),
            open: parseFloat(time['open']),
            close: parseFloat(time['close']),
            volume: parseFloat(time['volume'])
          });
        }

        console.log("bars",bars);
        onHistoryCallback(bars, { noData: bars.length === 0 });
      // })
      // .catch(err => onErrorCallback(err));
  }

  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    // Implement this method if you need real-time updates
  }

  unsubscribeBars(subscriberUID) {
    // Implement this method if you need real-time updates
  }
}

const TradingViewChart = ({ symbol, interval }) => {
  const tvContainer = useRef(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;

    script.onload = () => {
      setIsScriptLoaded(true);
    };

    script.onerror = () => {
      console.error('Failed to load TradingView script.');
    };

    document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && tvContainer.current) {
      const widgetOptions = {
        symbol: "Test",
        interval: interval,
        container_id: tvContainer.current.id,
        datafeed: new CustomDataFeed(),
        theme: "dark",
        width: "100%",
        library_path: '/charting_library/',
        locale: 'en',  
        charts_storage_url: 'https://saveload.tradingview.com',
        charts_storage_api_version: '1.1',
      };

      if (window.TradingView) {
        new window.TradingView.widget(widgetOptions);
      } else {
        console.error('TradingView is not available in the window object.');
      }
    }
  }, [isScriptLoaded, symbol, interval]);

  return <div id="tv_chart_container"  ref={tvContainer} style={{ width: '100%', height: '500px' }} />;
};

TradingViewChart.propTypes = {
  symbol: PropTypes.string.isRequired,
  interval: PropTypes.string,
};

TradingViewChart.defaultProps = {
  interval: 'D',
};

export default TradingViewChart;
