import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  NativeSelect,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import main_frame from "../Assets/Images/main_frame_.png";
import crown from "../Assets/Images/crown.png";
import heart from "../Assets/Images/heart.svg";
import rec from "../Assets/Images/bg.png";
import launch_coin from "../Assets/Images/launch_coin_new.gif";
import TimelineIcon from "@mui/icons-material/Timeline";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import HotPicks from "../Components/HotPicks";
import NFTBox from "../Components/NFTBox";
import CommunityHub from "../Components/CommunityHub";
import Footer from "../Components/Footer";
import CrownBox from "../Components/CrownBox";
import { ContractContext } from "../Context/ContractContext";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";
import MobileChains from "../Components/MobileChains";

const Home = () => {
  const { t } = useTranslation();
  const { coinData, crownData } = useContext(ContractContext);
  const { isConnected } = useAccount();
  const [sortOption, setSortOption] = useState('1');
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCoins, setFilteredCoins] = useState([]);
  
useEffect(() => {
  if (coinData) {
  
    let filtered = coinData
      .filter((coin) =>
        coin.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .filter((coin) => coin.published === true);

    if (sortOption === '1') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOption === '2') {
     filtered.sort((a, b) => b.marketValue - a.marketValue);
    }

    setFilteredCoins(filtered);
  } else {
    setFilteredCoins([]);
  }
}, [searchQuery, coinData, sortOption]);

  


  return (
    <Container>
      <Navbar />
      <Box
        sx={{
          color: "#fff",
          mt: "1rem",
          position: "relative",
        }}
        data-aos="fade-up"
      >
        <Typography
          component={"img"}
          src={main_frame}
          sx={{
            position: "absolute",
            top: "5%",
            transform: "translateY(-10%)",
            width: "100%",
          }}
        />
        {/* <Typography
              sx={{
                fontSize: { md: "48px", xs: "30px" },
                lineHeight: { md: "1.2", xs: "1.2" },
                fontFamily: "latoBold",
                color: "#E6820C",
                pt: { md: "2rem" },
                pl: { md: "7rem", xs: "0" },
                textAlign: { md: "inherit", xs: "center" },
                pb: "2rem",
              }}
            >
              No Presale, No Team Allocation - Only Fair Launches!
            </Typography> */}
        <Grid container spacing={2} position={"relative"}>
          {/* {isConnected && (
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ConnectButton label="Connect" />
            </Grid>
          )} */}
          <Grid item md={9} sm={12} xs={12}>
            {/* <Box
              position={"relative"}
              sx={{
                backgroundImage: `url(${network_bg})`,
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                mt: { md: "2rem", xs: "2rem" },
                p: { md: "4rem", xs: "1rem 0" },
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  "& img": {
                    border: "0.5px solid transparent",
                    cursor: "pointer",
                    transition: "0.3s all ease",
                    "&:hover": {
                      border: "0.5px solid #a632dd99",
                      borderRadius: "3px",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "latoBold",
                    pb: "1rem",
                  }}
                >
                  Supported Chains
                </Typography>
                <Box sx={{
                  display:"flex",
                  alignItems:"center",
                  gap:"0.2rem",
                  justifyContent:"center",
                  flexWrap:"wrap"
                }}>
                  <Typography component={"img"} src={Base} />
                  <Typography component={"img"} src={BSC} />
                  <Typography component={"img"} src={Solana} />
                  <Typography component={"img"} src={ton}  width={"90px"} p={"0.2rem"}/>
                </Box>
              </Box>
            </Box> */}
            <Box
              sx={{
                pt: { lg: "0", xs: "1rem" },
                "& .swiper-wrapper": {
                  justifyContent: "center",
                },
              }}
            >
              {/* <Box
        sx={{
          zIndex:"2",
          border:"1px solid rgb(126 46 164 / 50%)",
          backdropFilter:"blur(20px)",
          borderRadius:"20px",
          p:"15px 5px",
          top:{sm:"100px",xs:"80px"},
          background:"rgba(0, 0, 0, 0.38)",
          position:"fixed",
          display: {lg: "none",xs: "flex" },
          gap: "0.7rem",
          alignItems: "center",
          flexDirection:"column",
          "& img,svg": {
            transition: "0.3s all",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
            },
          },
          "& a": {
            color: "#fff",
            lineHeight: "0",
          },
          "& svg": {
            fontSize:"1.8rem",
            color:"#caa9da"
          },
        }}
      >
        <Typography component={"img"} src={twitter} width={"20px"}/>
        <TelegramIcon/>
      </Box> */}
              <Typography
                sx={{
                  fontSize: { md: "40px", xs: "30px" },
                  fontFamily: "latoBold",
                  textAlign: { md: "center", xs: "center" },
                  background:
                    "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  pb: "2rem",
                }}
              >
                {t(`Hot Picks`)}
              </Typography>
              {/* <Grid container spacing={2}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e, i) => (
            <Grid key={i} item lg={2} md={3} sm={4} xs={12}> */}
              <HotPicks />
              {/* </Grid>
          ))}
        </Grid> */}
            </Box>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            sx={{
              pt: { md: "2rem !important" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              component={"img"}
              src={launch_coin}
              sx={{
                width: "172px",
                // animation: `spin 7s linear infinite`,
                // "@keyframes spin": {
                //   "0%": {
                //     transform: "rotate(0deg)",
                //   },
                //   "100%": {
                //     transform: "rotate(360deg)",
                //   },
                // },
              }}
            />
            {/* <Box
              pt={"2rem"}
              sx={{
                "& p": {
                  fontSize: { md: "64px", xs: "40px" },
                  lineHeight: { md: "1.4", xs: "1.3" },
                  fontFamily: "latoBold",
                },
              }}
            >
              <Swiper
                style={{ height: "100px", marginTop: "2rem" }}
                direction={"vertical"}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                navigation={false}
                modules={[Autoplay, Navigation, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Typography color={"#F4F4F4"}>Transparency</Typography>
                </SwiperSlide>
                <SwiperSlide>
                  <Typography color={"#86EFAC"}>Security</Typography>
                </SwiperSlide>
                <SwiperSlide>
                  <Typography color={"#5624C2"}>Fairness</Typography>
                </SwiperSlide>
              </Swiper>
            </Box> */}
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: { lg: "block", xs: "none" },
          my: "4rem",
          position: "relative",
          backgroundImage: `url(${rec})`,
          backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
          backgroundPosition: "100% 100%",
          backgroundRepeat: "no-repeat",
          height: "330px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "7%",
            textAlign: "center",
          }}
        >
          <Typography
            component={"img"}
            src={crown}
            sx={{
              width: "50px",
              height: "50px",
              pb: "0.3rem",
              borderRadius: "5px",
            }}
          />
          <Typography
            component={"img"}
            src={crownData?.coinsData?.image}
            sx={{
              display: "block",
              width: "150px",
              height: "180px",
            }}
          />
        </Box>
        <Typography
          sx={{
            position: "absolute",
            top: "28px",
            right: "35px",
            fontSize: { md: "58px", xs: "30px" },
            lineHeight: { md: "1.2", xs: "1.2" },
            fontFamily: "MacondoSwashCaps-Regular",
            color: "#F5EE36",
            textShadow: "0 0.1em 10px red", // Initial shadow effect
            animation: `blooming 2s infinite`,
            "@keyframes blooming": {
              "0%": {
                textShadow: "0 0.1em 10px red", // Initial shadow
              },
              "50%": {
                textShadow:
                  "0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red",
              },
              "100%": {
                textShadow: "0 0.1em 10px red", // Return to initial shadow
              },
            },
          }}
        >
          Look who’s wearing the crown now!
        </Typography>
        <Box
          sx={{
            width: "72%",
            position: "absolute",
            bottom: "50%",
            transform: "translateY(100%)",
            right: "0",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              "& .text": {
                color: "#CAA9DA",
                fontSize: "20px",
                fontFamily: "lato",
              },
              "& .span_text": {
                color: "#fff",
                fontSize: "24px",
                fontFamily: "latoBold",
              },
            }}
          >
            <Grid item lg={6} xs={12}>
              <Typography className="text">
                Founded by:{" "}
                <Typography
                  component={"img"}
                  src={heart}
                  sx={{
                    width: "23px",
                    height: "23px",
                  }}
                />{" "}
                <span
                  className="span_text"
                  style={{ textTransform: "capitalize" }}
                >
                  {crownData?.username?.length > 8
                    ? `${crownData?.username.slice(
                        0,
                        4
                      )}...${crownData?.username.slice(-4)}`
                    : crownData?.username}
                </span>
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography className="text">
                Opinions:{" "}
                <span className="span_text">{crownData?.comments}</span>
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography className="text">
                value:{" "}
                <span className="span_text">
                  ${" "}
                  {crownData?.coinsData?.marketValue > 0
                    ? parseFloat(crownData?.coinsData?.marketValue).toFixed(2)
                    : 0}
                </span>{" "}
                <span className="span_text" style={{ color: "#7E2EA4" }}>
                  <TimelineIcon
                    sx={{ fontSize: "3rem", verticalAlign: "middle" }}
                  />
                </span>
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography className="text">
                Code:{" "}
                <span className="span_text" style={{ color: "#7E2EA4" }}>
                  {crownData?.coinsData?.symbol?.length > 8
                    ? `${crownData?.coinsData?.symbol.slice(
                        0,
                        4
                      )}...${crownData?.coinsData?.symbol.slice(-4)}`
                    : crownData?.coinsData?.symbol}
                </span>{" "}
                <span style={{ color: "#fff", fontSize: "18px" }}>
                  ( {crownData?.coinsData?.name} )
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ display: { lg: "none", xs: "block" } }}>
        <CrownBox crownData={crownData} />
      </Box>

      {/* hot picks */}

      <Box pb={"5rem"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexWrap: "wrap",
            pb: "2rem",
          }}
        >
          <FormControl
            className="custom_form"
            sx={{ width: { lg: "20%", sm: "30%", xs: "100%" } }}
          >
            <NativeSelect  value={sortOption}
            onChange={handleSortChange} className="custom_select">
              <option value="1">Sort by: Recently Added</option>
              <option value="2">Sort by: Market Cap</option>
            </NativeSelect>
          </FormControl>
          <TextField
            sx={{ width: { lg: "75%", sm: "65%", xs: "100%" } }}
            autoComplete="off"
            id="email"
            name="email"
            type="email"
            className="custom_input"
            placeholder="Search for tokens...."
            variant="standard"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        <Grid container spacing={1} justifyContent={"center"}>
           {/* &&
            filteredCoins
              ?.filter((e) => e.published === true) */}
          {filteredCoins
              ?.map((e, i) => (
                <Grid key={i} item lg={2.4} md={3} sm={3} xs={6}>
                  <NFTBox data={e} sortOption={sortOption}/>
                </Grid>
              ))}
        </Grid>
      </Box>
      {/* {coinData?.length>0 &&  <PoolListing/>} */}
      <Box mb={"3rem"}>
        <Typography
          sx={{
            fontSize: { md: "40px", xs: "30px" },
            fontFamily: "latoBold",
            textAlign: { md: "center", xs: "center" },
            background:
              "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            pb: "2rem",
          }}
        >
          {t(`Crypto Community Hub`)}{" "}
        </Typography>
        <CommunityHub />
      </Box>
      <Box>
        <Footer />
      </Box>
      <MobileChains />
    </Container>
  );
};

export default Home;
