import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import hot_icon from "../../Assets/Images/hot_icon.png";
import like from "../../Assets/Images/like.svg";
import dislike from "../../Assets/Images/dislike.svg";
import reply from "../../Assets/Images/reply.svg";
import share from "../../Assets/Images/share.svg";
import report from "../../Assets/Images/report.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API_URL } from "../../Config";
import { ContractContext } from "../../Context/ContractContext";
import { useAccount } from "wagmi";
import ReplyModal from "./ReplyModal";
import { useParams } from "react-router-dom";
import ReplyMessage from "./ReplyMessage";

const Message = ({ data, index }) => {
  const { id: projectId } = useParams();
  const { isConnected } = useAccount();
  const { getComments } = useContext(ContractContext);
  const [loading, setLoading] = useState(false);
  const [replyComment, setReplyComment] = useState([]);
  const [likes, setLikes] = useState([]);
  const [dislikes, setDislikes] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${API_URL}/delete/comment/${data?._id}`
      );

      if (response.status === 200) {
        getComments(projectId);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getLikesByCommentId = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/likes/comment/${data?._id}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setLikes(_data.data.likes);
        setDislikes(_data.data.dislikes);
      })
      .catch((err) => {
        setLikes([]);
        setDislikes([]);
      });
  };
  const getReplyComments = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/get/reply/${data?._id}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setReplyComment(_data.data.data);
      })
      .catch((err) => {
        //  console.log(err);
      });
  };
  const createLike = async (like) => {
    try {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      };
      const res = await axios.post(
        `${API_URL}/create/like`,
        {
          commentId: data?._id,
          like: like,
        },
        config
      );
      if (res.status === 201 || res.status === 200) {
        getLikesByCommentId();
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    if (data?._id) {
      getLikesByCommentId();
      getReplyComments();
    }
  }, [data?._id]);
  return (
    <Box
      key={index}
      color={"#fff"}
      sx={{
        pb: "1rem",
        "& .date, .like": {
          color: "#909090",
          fontFamily: "lato",
          fontSize: "16px",
        },
        "& .like": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          mt: "0.5rem",
          background: "rgb(126 46 164/ 15%)",
          borderRadius: "5px",
          p: ".8rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography
              component={"img"}
              src={hot_icon}
              sx={{
                width: "28px",
              }}
            />
            <Typography
              component={"span"}
              sx={{
                textAlign: "center",
                p: "5px 15px",
                background: "rgb(126 46 164/ 23%)",
                borderRadius: "3px",
                fontFamily: "latoBold",
                fontSize: { sm: "16px", xs: "14px" },
                wordBreak: "break-all",
              }}
            >
              {data?.userId?.username}
            </Typography>
          </Box>
          <Typography className="date">
            {new Date(data?.createdAt).toLocaleDateString()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            pt: "0.5rem",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              "& .p": {
                fontSize: "16px",
                color: "#CAA9DA",
                fontFamily: "lato",
                cursor: "pointer",
              },
            }}
          >
            <Typography className="date" sx={{ color: "#fff !important" }}>
              {data?.comment}
            </Typography>
            {isConnected && (
              <Typography onClick={handleClickOpen} className="p">
                <Typography component={"img"} src={reply} /> Reply
              </Typography>
            )}
            {/* <Typography className="p">
              <Typography component={"img"} src={report} /> Report
            </Typography> */}
            <Button
              onClick={handleDelete}
              disableRipple
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#CAA9DA",
                fontFamily: "lato",
                lineHeight: "0",
                p: "0",
                textTransform: "capitalize",
                gap: "0.2rem",
              }}
            >
              <DeleteIcon sx={{ fontSize: "1.2rem" }} />{" "}
              {loading ? "Deleting.." : "Delete"}
            </Button>
          </Box>
          {isConnected && (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Typography className="like" onClick={() => createLike(true)}>
                {" "}
                <Typography
                  component={"img"}
                  src={like}
                  sx={{
                    width: "18px",
                  }}
                />{" "}
                {likes?.length}
              </Typography>
              <Typography className="like" onClick={() => createLike(false)}>
                {" "}
                <Typography
                  component={"img"}
                  src={dislike}
                  sx={{
                    width: "18px",
                    verticalAlign: "middle",
                  }}
                />{" "}
                {dislikes?.length}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* ///reply */}
      {replyComment &&
        replyComment?.map((item, i) => (
          <ReplyMessage
            data={item}
            index={i}
            mainReplyId={data?._id}
            getReplyComments={getReplyComments}
          />
        ))}
      <ReplyModal
        getReplyComments={getReplyComments}
        open={open}
        handleClose={handleClose}
        commentId={data?._id}
      />
    </Box>
  );
};

export default Message;
