// src/components/CandlestickChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const CandlestickChart = ({chartData}) => {
  const chartContainerRef = useRef(null);
  function getDateFormat(){
    return new Date().getFullYear()+"-"+(new Date().getMonth() < 10 ? "0"+(new Date().getMonth()+1): new Date().getMonth())+"-"+(new Date().getDate() < 10 ? "0"+(new Date().getDate()): new Date().getDate())
  }
  useEffect(() => {
    const chart = createChart(chartContainerRef.current, { 
      height: 400,
      layout: {
        background: { color: "#222" },
        textColor: "#C3BCDB",
      },
      grid: {
        vertLines: { color: "#444" },
        horzLines: { color: "#444" },
      },
      
    });

    const candlestickSeries = chart.addCandlestickSeries({
      upColor: 'rgba(255, 144, 0, 1)',
      downColor: 'rgba(0, 144, 255, 1)',
      borderDownColor: 'rgba(0, 144, 255, 1)',
      borderUpColor: 'rgba(255, 144, 0, 1)',
      wickDownColor: 'rgba(0, 144, 255, 1)',
      wickUpColor: 'rgba(255, 144, 0, 1)',
    });
    if(chartData.length > 0){
      console.log(chartData);
      candlestickSeries.setData(chartData)
    }
    else{
      candlestickSeries.setData([
        { time: getDateFormat(), open: 0, high: 0, low: 0, close: 0 }, 
      ]);  
    }
    

    candlestickSeries.applyOptions({
      priceFormat: {
        type: 'price',
        precision: 6,
        minMove: 0.000001,
     }
  });

    return () => {
      chart.remove();
    };
  }, [chartData]);

  return (
    <div style={{"width" : "100%"}} ref={chartContainerRef} />
  );
};

export default CandlestickChart;
