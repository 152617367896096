import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import hot_icon from "../Assets/Images/hot_icon.png";
import hot_picks from "../Assets/Images/hot_picks.gif";
import { API_URL, POOL_ADDRESS } from "../Config";
import TOKEN_COIN_ABI from "../Config/TOKEN_COIN_ABI.json"
import axios from "axios";
import { useReadContract } from "wagmi";
import { formatEther } from "viem";
import { ChainIcons } from "./ChainIcons";

const HotPIckBox = ({ e }) => {
  const { data: getMarketCap } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getMarketCap",
    args: [e?._id],
  });

  return (
    <Box
       onClick={() => (window.location.href = `/nft-detail/${e?._id}`)}
      sx={{
        // background:"#7E2EA4",
        backgroundImage: `url(${hot_picks})`,
        cursor: "pointer",
        backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        border: "1px solid #7E2EA4",
        borderRadius: "4px",
        textAlign: "center",
        lineHeight: "0",
        position: "relative",
        "& .text": {
          //   color: "#CAA9DA",
          color: "#fff",
          fontWeight: "600",
          fontSize: "12px",
          fontFamily: "lato",
        },
        "& .span_text": {
          color: "#fff",
          fontSize: "16px",
          fontFamily: "latoBold",
        },
        transition: "0.3s all ease",
        "&:hover": {
          boxShadow: "0 0 20px rgba(126, 46, 164, 0.8)",
        },
      }}
    >
    <Typography component={"img"} src={ChainIcons[e?.chain]} sx={{width:"26px",position:"absolute",zIndex:"1200",top:"5px",right:"5px"}}/>
      <Box sx={{ position: "relative", backdropFilter: "blur(25px)" }}>
        <Typography
          component={"img"}
          src={e?.image}
          sx={{
            my: "0.5rem",
            borderRadius:"5px",
            width: "70px",
            height: "70px",
          }}
        />
        <Typography
          sx={{
            color: "#fff",
            fontSize: "16px",
            fontFamily: "latoBold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            textTransform: "capitalize",
          }}
        >
          <Typography
            component={"img"}
            src={hot_icon}
            sx={{
              width: "23px",
              height: "23px",
            }}
          />{" "}
          {e?.name}
        </Typography>
        <Box
          sx={{
            background: "rgb(255 255 255 / 10%)",
            borderRadius: "3px 3px 0 0",
            mt: "0.8rem",
            p: "6px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography className="text">Market value</Typography>
            <Typography className="span_text">$ {getMarketCap >0?formatEther(getMarketCap):0} </Typography>
          </Box>
          <Box>
            <Typography className="text">Opinions</Typography>
            <Typography className="span_text">{e?.commentData?.length}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HotPIckBox;
