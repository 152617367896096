import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import pump_fun_logo from "../Assets/Images/pump_fun_logo.png";
import create_coin from "../Assets/Images/create_coin.gif";
// import user from "../Assets/Images/user.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import MobileNav from "./MobileNav";
import CustomButton from "./CustomButton";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { API_URL } from "../Config";
import axios from "axios";
import { useAccount } from "wagmi";
import { Button } from "@mui/material";
import Base from "../Assets/Images/Base.svg";
import BSC from "../Assets/Images/BSC.svg";
import Solana from "../Assets/Images/Solana.svg";
import ton from "../Assets/Images/ton.svg";
import twitter from "../Assets/Images/twitter.svg";
import TelegramIcon from "@mui/icons-material/Telegram";

const drawerWidth = 300;

function DrawerAppBar(props) {
  const { address, isConnected } = useAccount();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referer = searchParams.get("referer");
  React.useEffect(() => {
    if (referer) {
      localStorage.setItem("referer", referer);
    }
  }, [referer]);

  const createUser = async () => {
    try {
      const res = await axios.post(`${API_URL}/create/user`, {
        address: address,
      });
      if (res?.status === 201) {
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("username", res.data.data.username);
      }
      if (res?.status === 200) {
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("username", res.data.data.username);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 404) {
        console.log(err);
      }
    }
  };
  React.useEffect(() => {
    if (isConnected && address !== undefined) {
      createUser();
    }
  }, [isConnected, address]);
  React.useEffect(() => {
    if (!isConnected) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
    }
  }, [isConnected]);
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#fff" : "#fff",
      // padding: '0rem 0.8rem',
      borderRadius: "8px",
      display: "flex",
      transition: "0.5s",
      fontFamily: "lato",
      textDecoration: "none",
      alignItems: "center",
      // fontWeight: isActive ? '700' : '500',
    };
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <MobileNav handleDrawerToggle={handleDrawerToggle} cartValue={0} />
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        py: { sm: "0.8rem", xs: "0.4rem" },
        position: "sticky",
        top: "0",
        zIndex: "1200",
      }}
    >
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          position: "inherit",
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(20px)",
          borderRadius: "10px",
        }}
      >
        <Toolbar
          sx={{
            background: "rgb(20 20 20 / 25%)",
            borderRadius: "10px",
            justifyContent: "space-between",
            p: "10px 15px !important",
            border: "1px solid rgb(126 46 164 / 50%)",
          }}
        >
          <Link to={"/"} style={{ lineHeight: "0", textDecoration: "none" }}>
            {" "}
            {/* <Typography variant="h5" color={"#fff"} fontFamily={"lato"} fontWeight={600}>LOGO</Typography> */}
            <Typography
              component={"img"}
              src={pump_fun_logo}
              sx={{
                filter: "hue-rotate(45deg)",
                width: { md: "70px", xs: "55px" },
              }}
            />
          </Link>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: "none", color: "#fff" }, p: "0" }}
          >
            <MenuIcon />
          </IconButton> */}

          <Box
            sx={{
              display: { md: "none", lg: "flex", sm: "none", xs: "none" },
              gap: "1.2rem",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                "& img": {
                  border: "0.5px solid transparent",
                  cursor: "pointer",
                  transition: "0.3s all ease",
                  "&:hover": {
                    border: "0.5px solid #a632dd99",
                    borderRadius: "3px",
                  },
                },
              }}
            >
              {/* <Typography
                  sx={{
                    fontSize: "17px",
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "latoBold",
                    pb: "1rem",
                  }}
                >
                  Supported Chains
                </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.2rem",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography component={"img"} src={Base} width={"100px"} />
                {/* <Typography component={"img"} src={Blast} /> */}
                <Typography component={"img"} src={BSC} />
                <Typography component={"img"} src={Solana} />
                <Typography
                  component={"img"}
                  src={ton}
                  width={"85px"}
                  p={"0.2rem"}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: { lg: "flex" },
              gap: { sm: "1.2rem", xs: "0.8rem" },
              alignItems: "center",
              ml: { sm: "0", xs: "-10px" },
            }}
          >
            <Button
              component={Link}
              to="/create-coin"
              sx={{
                backgroundImage: `url(${create_coin})`,
                backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
                // background: "rgb(140,27,142)",
                // background:
                //   "linear-gradient(135deg, rgba(140,27,142,1) 0%, rgba(150,0,63,1) 24%, rgba(79,7,113,1) 100%)",
                fontSize: { sm: "16px", xs: "15px" },
                color: "rgba(255, 255, 255, 0.7)",
                fontFamily: "latoBold",
                transition: "all 0.3s ease",
                // backgroundSize: "200% 200%",
                textTransform: "capitalize",
                width: { sm: "200px", xs: "100%" },
                p: { sm: "5px", xs: "5px 15px" },
              }}
            >
              Create a fresh coin!
            </Button>
            <Box
              sx={{
                // py: "0.8rem",
                display: { lg: "flex", xs: "none" },
                gap: { sm: "0.5rem", xs: "0.3rem" },
                alignItems: "center",
                "& img,svg": {
                  transition: "0.3s all",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                },
                "& a": {
                  color: "#fff",
                  lineHeight: "0",
                },
                "& svg": {
                  fontSize: { sm: "1.8rem", xs: "1.6rem" },
                  color: "#caa9da",
                },
              }}
            >
              <Typography
                component={"img"}
                src={twitter}
                sx={{ width: { sm: "20px", xs: "18px" } }}
              />
              <TelegramIcon />
            </Box>
          </Box>
          <Box
            sx={{
              // py: "0.8rem",
              display: { lg: "none", xs: "flex" },
              gap: { sm: "0.7rem", xs: "0.3rem" },
              alignItems: "center",
              "& img,svg": {
                transition: "0.3s all",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              },
              "& a": {
                color: "#fff",
                lineHeight: "0",
              },
              "& svg": {
                fontSize: { sm: "1.8rem", xs: "1.6rem" },
                color: "#caa9da",
              },
            }}
          >
            <Typography
              component={"img"}
              src={twitter}
              sx={{ width: { sm: "20px", xs: "18px" } }}
            />
            <TelegramIcon />
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background:
                "linear-gradient(138deg, rgb(126 46 164 / 100%) 0%, rgb(0 0 0) 49%, rgb(126 46 164 / 101%) 100%)",
              padding: "1rem",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
