import { Box, Typography } from "@mui/material";
import React from "react";
import fb from "../Assets/Images/fb.svg";
import twitter from "../Assets/Images/twitter.svg";
import discord from "../Assets/Images/discord.svg";
import insta from "../Assets/Images/insta.svg";
import TelegramIcon from '@mui/icons-material/Telegram';
import ChooseLanguages from "./Languages/ChooseLanguages";

const Footer = () => {
  return (
    <Box
      sx={{
        pb:{lg:"1rem",sm:"4rem",xs:"2.2rem"},
        color: "#C9C9C9",
        mb: "1rem",
        pt: "4rem !important",
        p: "15px",
        display: "flex",
        justifyContent: { sm: "space-between", xs: "center" },
        gap: "1rem",
        flexDirection: { md: "row", xs: "column" },
        alignItems: "center",

        "& p": {
          fontSize: "16px",
          fontFamily: "lato",
        },
      }}
    >
    <ChooseLanguages/>
      <Typography>@2024 All rights reserved</Typography>
      <Typography>Privacy Policy</Typography>
      <Typography>Terms & Conditions</Typography>
      <Box
        sx={{
          // py: "0.8rem",
          display: "flex",
          gap: "0.7rem",
          alignItems: "center",
          "& img,svg": {
            transition: "0.3s all",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
            },
          },
          "& a": {
            color: "#fff",
            lineHeight: "0",
          },
          "& svg": {
            fontSize:"2.2rem",
            color:"#caa9da"
          },
        }}
      >
        {/* <Typography component={"img"} src={fb} />
        <Typography component={"img"} src={discord} /> */}
        <Typography component={"img"} src={twitter} />
        {/* <Typography component={"img"} src={insta} /> */}
        <TelegramIcon/>
      </Box>
    </Box>
  );
};

export default Footer;
