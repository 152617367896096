import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Typography,
  TextareaAutosize,
  Snackbar,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { API_URL } from '../../Config';
import { useParams } from 'react-router-dom';



export default function ReplyModal({handleClose,open,commentId,getReplyComments}) {
const {id:projectId} = useParams()
  const [loading, setLoading] = useState(false);
  
  const getInitialValues = () => {
    return {
      comment: "",
    };
  };
  const validationSchema = Yup.object({
    comment: Yup.string()
      .trim("Comment should not contain spaces")
      .required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      replyComment();
    },
  });
  const replyComment = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      };
      const res = await axios.post(
        `${API_URL}/create/comment`,
        {
          projectId: projectId,
          comment: formik.values.comment,
          replyTo:commentId
        },
        config
      );
      if (res.status === 201) {
        setLoading(false);
        getReplyComments()
        handleClose()
        // getComments(projectId)
        formik.resetForm();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      
      <Dialog 
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1rem",
            borderTop: "1px solid rgba(255, 255, 255, 0.22) !important",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiPaper-root": {
            width: "500px !important",
            background: "#00000085",
            background:
              "linear-gradient(305deg, rgb(38 0 252 / 5%), rgb(255 0 234 / 9%)) !important",
            border: "2px solid rgba(255, 255, 255, 0.22) !important",
            backdropFilter: "blur(12.5px)",
            color: "#fff",
            width: { md: "100% !important", xs: "300px !important" },
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Reply
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
              <Box
                textAlign="end"
                p="1rem"
                sx={{
                  background: "rgb(126 46 164 / 15%)",
                  borderRadius: "10px",
                }}
              >
                <TextareaAutosize
                  id="comment"
                  name="comment"
                  aria-label="minimum height"
                  minRows={3}
                  className="comment"
                  placeholder="Enter Comment.."
                  variant="standard"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "start",
                    }}
                  >
                    {formik.errors.comment}
                  </Typography>
                )}
                <Button
                  type="submit"
                  sx={{
                    my: "0.5rem",
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "latoBold",
                    transition: "all 0.3s ease",
                    textTransform: "capitalize",
                    // width: '100%',
                    border: "1px solid rgba(255, 255, 255, 0.22)",
                    lineHeight: "initial",
                    background: "rgb(126 46 164 / 15%)",
                    padding: "6px 25px",
                  }}
                >
                  {loading ? "Replying..." : "Reply"}
                </Button>
              </Box>
            </form>
        
        </DialogContent>
     
      </Dialog>
    </React.Fragment>
  );
}
