import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import nft from "../../Assets/Images/nft.png";
// import slider_1 from "../../Assets/Images/slider_1.svg";
import slider_2 from "../../Assets/Images/slider_2.svg";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import LanguageIcon from "@mui/icons-material/Language";
import { useAccount, useBalance, useBlockNumber, useReadContract } from "wagmi";
import { POOL_ADDRESS } from "../../Config";
import TOKEN_COIN_ABI from "../../Config/TOKEN_COIN_ABI.json";
import { useParams } from "react-router-dom";
import { erc20Abi, formatEther, formatUnits, parseEther } from "viem";
import { ContractContext } from "../../Context/ContractContext";
import { useQueryClient } from "@tanstack/react-query";

const LeftContainer = ({tokenData}) => {

  const { id: projectId } = useParams();
  const {crownData} = useContext(ContractContext)
  const {address} = useAccount()
  const {data:balance}= useBalance({
    address:address
  })


  const queryClient = useQueryClient();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: getContributeTokenBal } = useReadContract({
    abi: erc20Abi,
    address: tokenData?.address,
    functionName: "balanceOf",
    args: [POOL_ADDRESS],
  });
  const { data: alternateToken } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "alternateToken",
    args: [],
  });
  const { data: getTokenSymbol } = useReadContract({
    abi: erc20Abi,
    address: alternateToken,
    functionName: "symbol",
    args: [],
  });

  const { data: getTokenBal,queryKey:getTokenBalQuerryKey } = useReadContract({
    abi: erc20Abi,
    address: tokenData?.address,
    functionName: "balanceOf",
    args: [address],
  });
  const { data: projectRequiredCap,queryKey:projectRequiredCapQueryKey } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "projectRequiredCap",
    args: [],
  });
  const { data: getMarketCap,queryKey:getMarketCapQueryKey  } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getMarketCap",
    args: [projectId],
  });
  const { data: projects,queryKey:projectsQueryKey } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "projects",
    args: [projectId],
  });

 
  useEffect(() => {
    if (
      projectRequiredCapQueryKey ||
      getMarketCapQueryKey|| projectsQueryKey||getTokenBalQuerryKey
    ) {
      queryClient.invalidateQueries({ queryKey: projectRequiredCapQueryKey });
      queryClient.invalidateQueries({ queryKey: getMarketCapQueryKey });
      queryClient.invalidateQueries({ queryKey: projectsQueryKey });
      queryClient.invalidateQueries({ queryKey: getTokenBalQuerryKey });
   
    }
  }, [
    blockNumber,
    queryClient,
    projectRequiredCapQueryKey,
    getMarketCapQueryKey,
    projectsQueryKey,
    getTokenBalQuerryKey

  ]);
  const [rangeValue, setRangeValue] = useState(0);
  const [curveValue, setCurveValue] = useState(0);

  const change = (event) => {
    // setRangeValue(event.target.value);
  };
  useEffect(() => {
    const slider = document.getElementById("input");
    if(slider){
      slider.style.background = `linear-gradient(to right, #ffffff ${rangeValue}%, #7E2EA4 ${rangeValue}%)`;
    }
  }, [rangeValue]);
  useEffect(() => {
    const slider = document.getElementById("input_curve");
    if(slider){
      slider.style.background = `linear-gradient(to right, #007721 ${curveValue}%, #7E2EA4 ${curveValue}%)`;
    }
  }, [curveValue]);

  const formatNumber = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num?.toString();
  };
//  console.log(tokenData?.marketValue);
 
  useEffect(()=>{
    if(tokenData?.marketValue>0 && crownData?.coinsData?.marketValue>0){
      setRangeValue(parseFloat((tokenData?.marketValue||0)/(crownData?.coinsData?.marketValue||0)).toFixed(2)*100)
    }else{
      setRangeValue(0)
    }
  },[tokenData?.marketValue,crownData?.coinsData?.marketValue])

  useEffect(()=>{
      setCurveValue(parseFloat(formatEther(projects?.[6]||0)/formatEther(projectRequiredCap||0)).toFixed(4)*100)
  },[getTokenBal,projectRequiredCap])
  console.log(rangeValue);
  
  return (
    <>
      <Box
        sx={{
          background: "rgb(126 46 164/ 42%)",
          borderRadius: "5px",
          p: "1rem",
          textAlign: "center",
          "& .text_1": {
            fontFamily: "lato",
            fontSize: "15px",
          },
        }}
      >
{rangeValue ===100 ?
<Box>
  <Typography component={"img"} src={slider_2} width={"50px"} sx={{
         textShadow: "0 0.1em 10px red", // Initial shadow effect
            // transition:"all 2s ease",
            animation: `blooming 2s infinite ease`,
            "@keyframes blooming": {
              "0%": {
                textShadow: "0 0.1em 10px red", // Initial shadow
                transform: "scale(1)", // Initial scale
              },
              "50%": {
                textShadow:
                  "0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red",
                transform: "scale(1.1)", // Increase size to 1.2 times
              },
              "100%": {
                textShadow: "0 0.1em 10px red", // Return to initial shadow
                transform: "scale(1)", // Return to initial scale
              },
            },
  }}/>
</Box>
:
<>
<Typography
          sx={{
            fontSize: "18px",
            fontFamily: "lato",
            color: "#F5EE36",
            textShadow: "0 0.1em 10px red", // Initial shadow effect
            // transition:"all 2s ease",
            animation: `blooming 2s infinite ease`,
            "@keyframes blooming": {
              "0%": {
                textShadow: "0 0.1em 10px red", // Initial shadow
                transform: "scale(1)", // Initial scale
              },
              "50%": {
                textShadow:
                  "0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red",
                transform: "scale(1.1)", // Increase size to 1.2 times
              },
              "100%": {
                textShadow: "0 0.1em 10px red", // Return to initial shadow
                transform: "scale(1)", // Return to initial scale
              },
            },
          }}
        >
          Claim your crown!
        </Typography>
        <Box my={"0.8rem"}>
          <div className="slider-container">
            <input
              id="input"
              type="range"
              value={rangeValue}
              onChange={change}
              className="custom-slider"
            />
            {/* <Typography component={"img"} src={slider_2} sx={{position:"absolute",top:"0px",right:"0",zIndex:"1"}} /> */}

            <div className="value-label">{rangeValue}%</div>
          </div>
        </Box>
</>
        }
   
        <Typography className="text_1">
          {rangeValue==100?"You are":"Surpass"} the current crown holder
        </Typography>
        <Typography className="text_1" color={"#CAA9DA"}>
          @ $ {crownData?.coinsData?.marketValue>0 ? parseFloat(crownData?.coinsData?.marketValue).toFixed(2):0} market value
          {/* @ {formatNumber(formattedRequiredCap)} market value */}
        </Typography>
      </Box>
      <Box
        sx={{
          mt:"1rem",
          background: "rgb(126 46 164/ 42%)",
          borderRadius: "5px",
          p: "1rem",
          textAlign: "center",
          "& .text_1": {
            fontFamily: "lato",
            fontSize: "15px",
          },
        }}
      >
      <Typography className="text_1">
      bonding curve progress: {parseFloat(curveValue)||0}%
        </Typography>
        <Box my={"0.5rem"}>
          <div className="slider-container">
            <input
              id="input_curve"
              type="range"
              value={curveValue}
              // onChange={change}
              className="curve-slider"
            />
    
          </div>
        </Box>
        <Typography className="text_1" textAlign={"start"}>
        when the market cap reaches ${formatNumber(formatEther(projectRequiredCap||0))} all the liquidity from the bonding curve will be deposited into Uniswap and burned. progression increases as the price goes up.
        </Typography>
        <Typography className="text_1" textAlign={"start"} pt="0.5rem">
        there are {formatEther(getContributeTokenBal||0)} tokens still available for sale in the bonding curve and there is {formatEther(projects?.[6]||0)} {tokenData?.isToken==true?getTokenSymbol:balance?.symbol} in the bonding curve.
        </Typography> 
      </Box>
      <Box
        sx={{
          mt: "1rem",
          background: "rgb(126 46 164/ 42%)",
          borderRadius: "5px",
          p: ".5rem",
          "& .text_2": {
            fontFamily: "lato",
            fontSize: "15px",
            color: "#CAA9DA",
          },
          "& b": {
            color: "#fff",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Typography component={"img"} src={tokenData?.image} width={"100px"} height={"100px"} />
          <Box>
            <Typography className="text_2" sx={{textTransform:"capitalize"}} pb={"0.5rem"}>
              {tokenData?.name}
            </Typography>
            <Typography className="text_2" pb={"0.5rem"}>
              Market value: <b>$ {parseFloat(tokenData?.marketValue).toFixed(2)||0}</b>
            </Typography>
            <Typography className="text_2">
              Code: <b>{tokenData?.symbol}</b>
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "lato-light",
            pt: "0.3rem",
          }}
        >
       {tokenData?.desc}
        </Typography>
        <Box
          sx={{
            py: "0.8rem",
            display: "flex",
            gap: "0.7rem",
            alignItems: "center",
            "& svg": {
              transition: "0.3s all",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.2)",
              },
            },
            "& a": {
            color:"#fff",
            lineHeight:"0"
            },
          }}
        >
         <a href={tokenData?.twitterLink} target="_blank" rel="noreferrer"> <XIcon sx={{ fontSize: "1.2rem" }} /></a>
         <a href={tokenData?.websiteLink} target="_blank" rel="noreferrer">  <LanguageIcon /></a>
         <a href={tokenData?.telegramLink} target="_blank" rel="noreferrer">  <TelegramIcon /></a>
        </Box>
      </Box>
    </>
  );
};

export default LeftContainer;
