import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SolanaModal from "./SolanaModal";
import SolanaDisconnectModal from "./SolanaDisconnectModal";

const SolanaWalletConnect = ({ balance }) => {
  const isPhantomInstalled = window.phantom?.solana?.isPhantom;
  const [provider, setProvider] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [solBalance, setSolbalance] = useState(0);
  const [solanaModal, setSolanaModal] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const handleSolanaModalClose = () => {
    setSolanaModal(false);
  };
  const handleSolanaDisconnectModalClose = () => {
    setDisconnectModal(false);
  };

  useEffect(() => {
    if ("solana" in window) {
      const anyWindow = window;
      const provider = anyWindow.solana;

      if (provider.isPhantom) {
        setProvider(provider);

        provider
          .connect({ onlyIfTrusted: true })
          .then(({ publicKey }) => {
            setWalletAddress(publicKey.toString());
            fetchBalance(publicKey);
          })
          .catch((err) => console.error("Connection failed:", err));
      }
    }
  }, []);

  const connectWallet = async () => {
    if (provider) {
      try {
        const { publicKey } = await provider.connect();
        setWalletAddress(publicKey.toString());
      } catch (err) {
        console.error("Connection failed:", err);
      }
    }
  };

  const disconnectWallet = async () => {
    if (provider) {
      await provider.disconnect();
      setWalletAddress(null);
    }
  };

  const fetchBalance = async (publicKey) => {
    try {
      // alert("hi")
      // const _publicKey = new PublicKey(publicKey)
      try {
        const { data } = await axios({
          method: "GET",
          // url:ApiConfigs.getBalance,
          params: {
            // limit:limit,
            walletAddress: publicKey,
          },
        });
        // alert(data.balance)
        if (data.status == "OK") {
          setSolbalance(data.balance);
        }
      } catch (error) {
        console.log(error, "lol");
      }

      // setSolbalance(balance / 1e9); // Convert lamports to SOL
    } catch (err) {
      // alert(err)
      console.error("Failed to fetch balance:", err);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          pt: "0.5rem",
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: "0.5rem",
          "& button": {
            color: "#fff",
            border: "1px solid #7E2EA4",
            background: "#45125E",
            p: "0.4rem 1.5rem",
            // width:{md:"200px",xs:"100%"},
            textTransform: "capitalize",
            borderRadius: "6px",
            fontSize: "15px",
            "&:hover": {
              background: "#632283",
            },
            textTransform: "capitalize",
          },
        }}
      >
        {isPhantomInstalled && (
          // <ConnectButton label="Connect" />
          <>
            {!walletAddress && (
              <Button onClick={() => setSolanaModal(true)}>Connect</Button>
            )}
            {walletAddress && (
              <>
                <div>
                  <Box
                    sx={{
                      border: "1px solid #7E2EA4",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        borderRight: "1px solid #7E2EA4",
                        background: "#45125E",
                        p: "0.4rem 1rem 0.4rem 1rem",
                        borderRadius: "8px 0 0 8px",
                        cursor:"pointer"
                      }}
                      onClick={()=>setDisconnectModal(true)}
                    >
                      Wallet: {walletAddress.substr(0, 5)}...
                      {walletAddress.substr(walletAddress.length - 5, 5)}{" "}
                    </Button>
                    <span
                      style={{
                        color: "#fff",
                        // border: "1px solid #7E2EA4",
                        borderRadius: "6px",
                        padding: "0 1rem",
                      }}
                    >
                      Bal: {balance || solBalance} SOL
                    </span>
                  </Box>
                </div>
                {/* <Button onClick={() => disconnectWallet()}>Disconnect</Button> */}
              </>
            )}
          </>
        )}
        <SolanaModal
          handleSolanaModalClose={handleSolanaModalClose}
          setWalletAddress={setWalletAddress}
          solanaModal={solanaModal}
          walletAddress={walletAddress}
        />
        <SolanaDisconnectModal
          handleSolanaModalClose={handleSolanaDisconnectModalClose}
          setWalletAddress={setWalletAddress}
          solanaModal={disconnectModal}
          walletAddress={walletAddress}
        />
      </Box>
    </Box>
  );
};

export default SolanaWalletConnect;
