import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { API_URL } from "../../Config";
import axios from "axios";
import NFTBox from "../../Components/NFTBox";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py="1.5rem">{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TokenTabs = ({ user }) => {
  console.log(user);

  const [value, setValue] = useState(0);
  const [tokenData, setTokenData] = useState([]);
  const [tokenHeld, setTokenHeld] = useState([]);
  const { address } = useAccount();
  const filteredTokens = tokenData?.filter((e) => e.published === true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCreatedToken = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/token/${user?._id}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setTokenData(_data.data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  useEffect(() => {
    if (user?._id) {
      getCreatedToken();
    }
  }, [user?._id]);
  const getHeldToken = async () => {
    try {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      };
      const res = await axios.get(`${API_URL}/get/token/held`, config);
      if (res.status === 200) {
        setTokenHeld(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHeldToken();
  }, []);
  return (
    <Box sx={{ width: "100%", color: "#fff" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiButtonBase-root": {
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: "600",
            color: "#fff",
            fontFamily: "latoBold",
          },
          "& .MuiTab-root.Mui-selected": {
            color: "#caa9da",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          "& .MuiTabs-indicator": {
            border: "1px solid #7E2EA4",
          },
          "& .MuiTabs-flexContainer": {
            justifyContent: "center",
            borderBottom:"1px solid rgba(255, 255, 255, 0.22)"
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Tokens Created" {...a11yProps(0)} />
          <Tab label="Tokens Held" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={1} justifyContent={"center"}>
          {filteredTokens?.length === 0 ? (
            <Typography
              sx={{
                fontFamily: "latoBold",
                fontSize: "20px",
                textAlign: "center",
                p: "2rem",
                width: "100%",
                border: "1px dashed #7E2EA4",
                borderRadius: "5px",
              }}
            >
              Tokens not found.
            </Typography>
          ) : (
            filteredTokens?.map((e, i) => (
              <Grid key={i} item lg={2.4} md={3} sm={3} xs={6}>
                <NFTBox data={e} />
              </Grid>
            ))
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={1} justifyContent={"center"}>
          {tokenHeld?.length > 0 ? (
            tokenHeld?.map((e, i) => (
              <Grid key={i} item lg={2.4} md={3} sm={3} xs={6}>
                <NFTBox data={e} />
              </Grid>
            ))
          ) : (
            <Typography
              sx={{
                fontFamily: "latoBold",
                fontSize: "20px",
                textAlign: "center",
                p: "2rem",
                width: "100%",
                border: "1px dashed #7E2EA4",
                borderRadius: "5px",
              }}
            >
              Tokens not found.
            </Typography>
          )}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
};

export default TokenTabs;
