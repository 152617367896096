import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { ContractContext } from "../Context/ContractContext";
import HotPIckBox from "./HotPIckBox";
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default theme
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

const HotPicks = () => {
  const { coinData } = useContext(ContractContext);
  const splideRef = useRef(null);
  const splideOptions = {
    type: 'loop',
    arrows:false,
    cover: false,
    perPage: 6,
    perMove: 1,
    rewind: true,
    pagination: false,
    gap: '0.5rem',
    autoplay: true,
    interval: 1500,
    breakpoints: {
      1300: { perPage: 6, gap: '.5rem' },
      1200: { perPage: 4, gap: '.5rem' },
      600: { perPage: 2, gap: '.5rem' },
    },
  };

  return (
    <>
  
      <Swiper
        spaceBetween={20}
        slidesPerView={5}
        rewind={true}
        loop={true}
        autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      speed={600}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          750: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1000: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
        navigation={false} // Disable navigation
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper"
      >
        {coinData &&
          coinData
            .filter((e) => e.published === true)
            .map((e, i) => (
              <SwiperSlide key={i}>
                <HotPIckBox e={e} />
              </SwiperSlide>
            ))}
      </Swiper>
    
        {/* <Box>
          <Splide ref={splideRef}  options={splideOptions}>
            {coinData &&
              coinData
                .filter((e) => e.published === true)
                .map((e, i) => (
                  <SplideSlide key={i}>
                    <HotPIckBox e={e} />
                  </SplideSlide>
                ))}
          </Splide>
      </Box> */}
    </>
  );
};

export default HotPicks;
