import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import AccountCircleIcon from "@mui/icons-material/AccountCircle";
  import CloudUploadIcon from "@mui/icons-material/CloudUpload";
  import { API_URL } from "../../Config";
  import axios from "axios";
  import CloseIcon from "@mui/icons-material/Close";

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle
        sx={{
          m: 0,
          fontSize: "1.2rem",
          p: 1.2,
          borderBottom: "2px solid rgba(255, 255, 255, 0.22)",
          display:"flex",
          gap:"5px",
          alignItems:"center"
        }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 6,
              top: 6,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

const EditProfile = ({user,setEditModal,editModal,setImage,image,getUser}) => {
    const [preview, setPreview] = useState("");
    const [imageErr, setImageErr] = useState("");
    const [responseErr, setResponseErr] = useState("");
    const handleImage = (e) => {
      setImageErr("");
      setImage(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    };
    const getInitialValues = () => {
        return {
          // address: "",
          username: "",
        };
      };
      useEffect(() => {
        if (user != null) {
          formik.setValues({
            address: user?.address || "",
            username: user?.username || "",
          });
        }
        // eslint-disable-next-line
      }, [user]);
      const validationSchema = Yup.object({
        // address: Yup.string()
        //   .trim("address should not contain spaces")
        //   .required("Required"),
        username: Yup.string()
          .trim("Username should not contain spaces")
          .required("Required"),
      });
      const initialValues = getInitialValues();
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (
          values,
          { setErrors, setSubmitting, resetForm, errors }
        ) => {
          if (errors) {
            setErrors(errors);
          }
          //   if (image !== null) {
          updateUser();
          //   } else {
          //     setImageErr("Please select image");
          //   }
        },
      });
      const [loading, setLoading] = useState(false);
      const updateUser = async () => {
        setLoading(true);
    
        try {
          const formData = new FormData();
          formData.append("username", formik.values.username);
          formData.append("image", image); // Assuming 'imageFile' is the file input value
    
          const res = await axios.put(`${API_URL}/update/user`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-access-token": localStorage.getItem("access_token"),
            },
          });
    
          if (res.status === 200) {
            setPreview("");
            setImage(null);
            getUser();
            setLoading(false);
            setEditModal(false)
          } else {
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 400) {
            setResponseErr(err.response.data.error);
          }
          setLoading(false);
        }
      };
  return (
    <div>
     <Dialog
        onClose={()=>setEditModal(false)}
        disableScrollLock
        aria-labelledby="customized-dialog-title"
        open={editModal}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.2rem 1rem",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(2px)",
          },
          "& .MuiPaper-root": {
            // maxWidth: "90% !important",
            // maxWidth: "400px !important",
            background: "#000",
            backgroundImage:
              "linear-gradient(305deg, rgb(38 0 252 / 5%), rgb(255 0 234 / 9%))",
            border: "2px solid rgba(255, 255, 255, 0.22) !important",
            backdropFilter: "blur(12.5px)",
            color: "#fff",
            width: { md: "100% !important", xs: "300px !important" },
            overflowX: { md: "auto", xs: "scroll" },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={()=>setEditModal(false)}
        >
             {user?.image ? (
                <Typography
                  component={"img"}
                  src={user?.image}
                  sx={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    border: "1px solid rgb(255 255 255 / 50%)",
                  }}
                />
              ) : (
                <AccountCircleIcon
                  sx={{
                    fontSize: "2.5rem",
                  }}
                />
              )} Edit Profile
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Box sx={{   color: "#fff",
            // py: "1rem",
            // py: { lg: "9rem", md: "6rem", xs: "2rem" },
            "& .custom_text": {
              fontSize: "32px",
              fontFamily: "latoBold",
              background:
                "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            "& .MuiFormControl-root": {
              mt: "0.2rem",
              width: "100%",
              border: "1px solid transparent !important",
              borderRadius: "3px",
              background: "rgb(217 217 217 / 8%)",
              color: "#fff",
              "&:focus-Visible": {
                outline: "none !important",
                border: "none !important",
              },
              "&:hover": {
                border: "none",
              },

              "&:focus": {
                boxShadow: "none !important",
                border: "2px solid transparent !important",
                borderRadius: "0px !important ",
              },
              "& .MuiInputBase-root": {
                "&::before": {
                  display: "none",
                },
                "&::after": {
                  display: "none",
                },
              },
              "& input": {
                padding: "10px",
                fontSize: "16px",
                borderRadius: "10px",
                fontWeight: "500",
                fontFamily: "lato !important",
                color: "#AEAEAE !important",
                "&::placeholder": {
                  color: "#fff !important",
                  fontFamily: "lato",
                  fontSize: "16px",
                },
              },
            },
            "& .error": {
              marginTop: "0.5rem !important",
              fontSize: "14px !important",
              color: "red !important",
              textAlign: "start",
            },
          }}>
            {/* <Typography
              className="custom_text"
              sx={{ textAlign: { md: "center", xs: "center" } }}
            >
              My Profile
            </Typography> */}
            <Box textAlign={"center"}>
              {user?.image ? (
                <Typography
                  component={"img"}
                  src={user?.image}
                  sx={{
                    my: "0.5rem",
                    borderRadius: "50%",
                    width: {sm:"80px",xs:"80px"},
                    height: {sm:"80px",xs:"80px"},
                    border: "1px solid rgb(255 255 255 / 50%)",
                  }}
                />
              ) : (
                <AccountCircleIcon
                  sx={{
                    fontSize: {sm:"6rem",xs:"6rem"},
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                mt: "0.5rem",
                p: {sm:"2rem",xs:"1rem"},
                border: "1px dashed #7E2EA4",
                borderRadius: "10px",
                mb: "1rem",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "rgb(255 255 255 / 50%)",
                  fontFamily: "lato",
                  fontSize: "16px",
                }}
              >
                PNG, JPG, GIF, WEBP,
              </Typography>
              <Button
                sx={{
                  mt: "1rem",

                  border: "1px solid #7E2EA4",
                  background: "#45125E",
                  p: "0.4rem 1rem",
                  // width:{md:"200px",xs:"100%"},
                  textTransform: "capitalize",
                  fontFamily: "latoBold",
                  "&:hover": {
                    background: "#632283",
                  },
                }}
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Choose File
                <input
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={handleImage}
                  type="file"
                />
              </Button>
              <Typography pt={".2rem"}>{image?.name}</Typography>
              {imageErr && (
                <Typography variant="body1" className="error">
                  {imageErr}
                </Typography>
              )}
              {preview && (
                <Box mt="1rem">
                  <img
                    src={preview}
                    alt="Preview"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              )}
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                {/* <TextField
                  autoComplete="off"
                  fullWidth
                  id="address"
                  name="address"
                  type="text"
                  // className={classes.bin1}
                  placeholder="Enter your address"
                  variant="standard"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address && (
                  <Typography variant="body1" className="error">
                    {formik.errors.address}
                  </Typography>
                )} */}
                <Typography variant="body1" sx={{
                    fontSize:"14px"
                }}>
                   Username
                  </Typography>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="username"
                  name="username"
                  type="text"
                  // className={classes.bin1}
                  placeholder="Enter your username"
                  variant="standard"
                  value={formik.values.username}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setResponseErr("");
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.username && formik.errors.username && (
                  <Typography variant="body1" className="error">
                    {formik.errors.username}
                  </Typography>
                )}
                {responseErr && (
                  <Typography variant="body1" className="error">
                    {responseErr}
                  </Typography>
                )}
                <Box pt={"1.5rem"} textAlign={"center"}>
                  <Button
                    type={"submit"}
                    sx={{
                      border: "1px solid #7E2EA4",
                      background: "#45125E",
                      p: "8px 1rem",
                      color: "#fff",
                      fontFamily: "latoBold",
                      width: { md: "200px", xs: "150px" },
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "#632283",
                      },
                    }}
                  >
                    {loading ? "Loading..." : "Update profile"}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

    </div>
  )
}

export default EditProfile