import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { API_URL } from "../../Config";
import axios from "axios";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TokenTabs from "./TokenTabs";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import EditProfile from "./EditProfile";
import { useAccount } from "wagmi";

const Profile = () => {
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const {address} = useAccount()

  const getUser = async () => {
    try {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      };
      const res = await axios.get(`${API_URL}/get/user`, config);
      if (res.status === 200) {
        setUser(res.data.user);
        localStorage.setItem("username", res.data.user.username);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
  }, [address]);
  const getInitialValues = () => {
    return {
      // address: "",
      username: "",
    };
  };
  useEffect(() => {
    if (user != null) {
      formik.setValues({
        address: user?.address || "",
        username: user?.username || "",
      });
    }
    // eslint-disable-next-line
  }, [user]);
  const validationSchema = Yup.object({
    // address: Yup.string()
    //   .trim("address should not contain spaces")
    //   .required("Required"),
    username: Yup.string()
      .trim("Username should not contain spaces")
      .required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      //   if (image !== null) {
      updateUser();
      //   } else {
      //     setImageErr("Please select image");
      //   }
    },
  });
  const [loading, setLoading] = useState(false);
  const updateUser = async () => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("username", formik.values.username);
      formData.append("image", image); // Assuming 'imageFile' is the file input value

      const res = await axios.put(`${API_URL}/update/user`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": localStorage.getItem("access_token"),
        },
      });

      if (res.status === 200) {
        setPreview("");
        setImage(null);
        getUser();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 400) {
        setResponseErr(err.response.data.error);
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Container>
        <Navbar />
        <Button
          sx={{
            mt: "1rem",
            border: "1px solid rgb(255 255 255 / 50%)",
            background: "transparent",
            color: "rgb(255 255 255 / 50%)",
            p: "0.4rem 0.9rem",
            fontSize: "16px",
            textTransform: "capitalize",
            fontFamily: "latoBold",
            "&:hover": {
              background: "transparent",
            },
          }}
          onClick={() => window.history.back()}
          variant="contained"
          tabIndex={-1}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Back
        </Button>
        <Box
          sx={{
            color: "#fff",
            py: "1rem",
            // py: { lg: "9rem", md: "6rem", xs: "2rem" },
            "& .custom_text": {
              fontSize: "32px",
              fontFamily: "latoBold",
              background:
                "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
          }}
        >
          <Typography
            className="custom_text"
            sx={{ textAlign: { md: "center", xs: "center" } }}
          >
            My Profile
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {user?.image ? (
              <Typography
                component={"img"}
                src={user?.image}
                sx={{
                  my: "0.5rem",
                  borderRadius: "50%",
                  width: "80px",
                  height: "80px",
                  border: "1px solid rgb(255 255 255 / 50%)",
                }}
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  fontSize: "6rem",
                }}
              />
            )}
            <Box textAlign={"end"}>
            <Typography
              sx={{ fontSize: "16px", fontFamily: "lato", fontWeight: "600" }}
            >
              @{user?.username}
            </Typography>
         
              <Button
              disableRipple
                    sx={{
                      // mt:"0.3rem",
                      // border: "1px solid #7E2EA4",
                      // background: "#45125E",
                      fontSize:"16px",
                      p: "5px 8px",
                      minWidth:"auto",
                      lineHeight:"1.6",
                      color: "#ffffffd1",
                      fontFamily: "latoBold",
                      // width: { md: "200px", xs: "100%" },
                      textTransform: "capitalize",
                      "&:hover": {
                        // background: "#632283",
                         color:"#a669c2",
                         textDecoration:"underline"
                      },
                      "& svg":{
                        // transition:"0.3s all",
                        ml:"3px",
                        mt:"-3px",
                        fontSize:"1.2rem",
                        "&:hover":{
                          // color:"#a669c2"
                        }
                      }
                    }}
                    onClick={()=>setEditModal(true)}
                  >
                    Edit {" "}<BorderColorRoundedIcon/>
                  </Button>
          </Box>
          </Box>
          <Box textAlign={"center"} py={"0.5rem"}>
            <Typography
              sx={{
                fontSize: {sm:"16px",xs:"13px"},
                display: "inline-block",
                color: "rgba(255, 255, 255, 0.7)",
                fontFamily: "lato",
                fontWeight: "600",
                textAlign: "center",
                border: "1px dashed rgba(255, 255, 255, 0.3)",
                borderRadius: "5px",
                p: {sm:"5px 10px",xs:"4px"},
              }}
            >
              {user?.address}
              {/* {user?.address ? `${user?.address?.slice(0, 6)}...${user?.address?.slice(-4)}` : "NA"} */}
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{height:"3rem",display:{lg:"block",xs:"none"}}}/> */}
        <TokenTabs user={user} />
        <Footer />
      </Container>
      <EditProfile setEditModal={setEditModal} editModal={editModal} user={user} setImage={setImage} image={image} getUser={getUser}/>
    </>
  );
};

export default Profile;
