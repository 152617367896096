import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import hot_1 from "../Assets/Images/hot_1.png";
import hot_icon from "../Assets/Images/hot_icon.png";
import list from "../Assets/Images/list.gif";
import { API_URL, POOL_ADDRESS } from "../Config";
import axios from "axios";
import { useReadContract } from "wagmi";
import TOKEN_COIN_ABI from "../Config/TOKEN_COIN_ABI.json"
import { formatEther } from "viem";
import { ChainIcons } from "./ChainIcons";

const NFTBox = ({ data }) => {

  const { data: getMarketCap } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getMarketCap",
    args: [data?._id],
  });
  return (
    <Box
      onClick={() => (window.location.href = `/nft-detail/${data?._id}`)}
      sx={{
        backgroundImage: `url(${list})`,
        backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
        overflow: "hidden",
        border: "1px solid #ffffffba",
        borderRadius: "4px",
        // textAlign: "center",
        lineHeight: "0",
        position: "relative",
        "& .text": {
          color: "#e3c7f0",
          //   color: "#fff",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "1.3",
          fontFamily: "lato",
        },
        "& .span_text": {
          color: "#fff",
          fontSize: {sm:"16px",xs:"14px"},
          fontFamily: "latoBold",
        },
        transition: "0.3s all ease",
        "&:hover": {
          boxShadow: "0 0 20px rgba(126, 46, 164, 0.8)",
        },
      }}
    >
     <Typography component={"img"} src={ChainIcons[data?.chain]} sx={{width:"26px",position:"absolute",zIndex:"1",top:"5px",right:"5px"}}/>
      <Box
        sx={{ position: "relative", p: "10px", backdropFilter: "blur(35px)" }}
      >
        <Grid container spacing={1} mb={"0.5rem"}>
          <Grid item xs={4}>
            <Typography
              component={"img"}
              src={data?.image}
              sx={{
                // my: "0.5rem",
                borderRadius:"5px",
                width: { md: "65px", xs: "100%" },
                height: {md:"75px",xs:"auto"},
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography className="text" pb={"0.2rem"}>
              Code: <span style={{ color: "#fff" }}>{data?.symbol}</span>
            </Typography>
            <Typography className="text" textTransform="capitalize" pb={"0.2rem"}>
              {data?.name}
            </Typography>
            <Typography className="text" height={"40px"}>
              {data?.desc?.length > 30
                ? `${data?.desc?.substring(0, 30)}...`
                : data?.desc}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: "#fff",
            fontSize: {sm:"18px",xs:"16px"},
            fontFamily: "latoBold",
            display: "flex",
            // justifyContent: "center",
            textTransform: "capitalize",
            alignItems: "center",
            gap: "0.5rem",
            pt: "0.4rem",
          }}
        >
          <Typography
            component={"img"}
            src={hot_icon}
            sx={{
              width: "23px",
              height: "23px",
            }}
          />{" "}
          {data?.name}
        </Typography>
        <Box
          sx={{
            // background: "rgb(255 255 255 / 10%)",
            borderRadius: "3px 3px 0 0",
            mt: "0rem",
            py: "5px",
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography className="text">Market value</Typography>
            <Typography className="span_text">$ {getMarketCap ?formatEther(getMarketCap):0} </Typography>
          </Box>
          <Box>
            <Typography className="text">Opinions</Typography>
            <Typography className="span_text">{data?.commentData?.length}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NFTBox;
