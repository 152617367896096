import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({ text, width, type,background }) => {
  return (
    <Button
      type={type}
      sx={{
        border: "1px solid #7E2EA4",
        background: background ? background:"#45125E",
        p: "8px 1rem",
        color: "#fff",
        fontFamily:"latoBold",
        width: { md: width, xs: "100%" },
        textTransform: "capitalize",
        "&:hover": {
          background: "#632283",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
