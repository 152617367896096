import {
  Box,
  Container,
  Button,
  Grid,
  Typography,
  TextareaAutosize,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import NftSlider from "./NftSlider";
import LeftContainer from "./LeftContainer";
import TradingChart from "./TradingChart";
import TradeContainer from "./TradeContainer";
import Message from "./Message";
import { useAccount } from "wagmi";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_URL } from "../../Config";
import { ContractContext } from "../../Context/ContractContext";
import MobileChains from "../../Components/MobileChains";
import TradingViewChart from "./TradingChart";
import LightweightChart from "./LightWeightTradingChart";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import SolanaWalletConnect from "../../Components/SolanaWalletConnect";

const NftDetailPage = () => {
  const getInitialValues = () => {
    return {
      comment: "",
    };
  };
  const validationSchema = Yup.object({
    comment: Yup.string()
      .trim("Comment should not contain spaces")
      .required("Required"),
  });
  const initialValues = getInitialValues();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      if (errors) {
        setErrors(errors);
      }
      createComment();
    },
  });
  const { id: projectId } = useParams();
  const { commentData, getComments, selectedChain } =
    useContext(ContractContext);
  const { address, isConnected } = useAccount();
  const [tokenChartData, setTokenChartData] = useState({});
  const [tokenData, setTokenData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (projectId) {
      getComments(projectId);
      getTokenDetail();
      getTokenChartDetail();
    }
  }, [projectId]);

  const createComment = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      };
      const res = await axios.post(
        `${API_URL}/create/comment`,
        {
          projectId: projectId,
          comment: formik.values.comment,
        },
        config
      );
      if (res.status === 201) {
        setLoading(false);
        setShowSnackbar(true);
        getComments(projectId);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
        formik.resetForm();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTokenChartDetail = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/get/chart/trades/${projectId}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((response) => {
        setTokenChartData(response.data.ohlcValues);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getTokenDetail = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/token-coin/${projectId}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setTokenData(_data.data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "green",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor: "#131722",
            color: "green",
            fontSize: "15px",
            fontFamily: "latoBold",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          Comment submitted Successfully.
        </Alert>
      </Snackbar>
      <Container>
        <Navbar />
        {selectedChain === 1 ? (
          <SolanaWalletConnect />
        ) : (
          <>
            {isConnected && (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: "0.5rem",
                }}
              >
                <ConnectButton label="Connect" />
              </Grid>
            )}
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              mt: "1rem",
              border: "1px solid rgb(255 255 255 / 50%)",
              background: "transparent",
              color: "rgb(255 255 255 / 50%)",
              p: "0.3rem 0.7rem",
              fontSize: "16px",
              textTransform: "capitalize",
              fontFamily: "latoBold",
              "&:hover": {
                background: "transparent",
              },
            }}
            component={Link}
            to="/"
            variant="contained"
            tabIndex={-1}
            startIcon={<KeyboardBackspaceIcon />}
          >
            Back
          </Button>
   {isConnected&&<Button
            sx={{
              mt: "1rem",
              border: "1px solid rgb(255 255 255 / 50%)",
              background: "transparent",
              color: "rgb(255 255 255 / 50%)",
              p: "0.4rem 0.9rem",
              fontSize: "16px",
              textTransform: "capitalize",
              fontFamily: "latoBold",
              "&:hover": {
                background: "transparent",
              },
            }}
            component={Link}
            to="/profile"
            variant="contained"
            tabIndex={-1}
            startIcon={<AccountCircleIcon />}
          >
            Profile
          </Button>}
        </Box>
        <Box my="2rem">{/* <NftSlider /> */}</Box>
        <Grid container spacing={2} color={"#fff"}>
          <Grid item lg={3} md={3.5} sm={12} xs={12}>
            <LeftContainer tokenData={tokenData} />
          </Grid>
          <Grid item lg={6} md={5} sm={12} xs={12}>
            {/* <TradingViewChart width="100%" symbol={tokenData?.symbol} interval="D" /> */}

            <LightweightChart chartData={tokenChartData} />
          </Grid>
          <Grid item lg={3} md={3.5} sm={12} xs={12}>
            <TradeContainer tokenData={tokenData} />
          </Grid>
        </Grid>
        <Box my={"3rem"}>
          <Typography
            sx={{
              fontSize: "32px",
              fontFamily: "latoBold",
              background:
                "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Token Opinions
          </Typography>
          {isConnected && (
            <form onSubmit={formik.handleSubmit}>
              <Box
                textAlign="end"
                my="1.5rem"
                p="1rem"
                sx={{
                  background: "rgb(126 46 164 / 15%)",
                  borderRadius: "10px",
                }}
              >
                <TextareaAutosize
                  id="comment"
                  name="comment"
                  aria-label="minimum height"
                  minRows={3}
                  className="comment"
                  placeholder="Enter Comment.."
                  variant="standard"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "start",
                    }}
                  >
                    {formik.errors.comment}
                  </Typography>
                )}
                <Button
                  type="submit"
                  sx={{
                    my: "0.5rem",
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "latoBold",
                    transition: "all 0.3s ease",
                    textTransform: "capitalize",
                    // width: '100%',
                    border: "1px solid rgba(255, 255, 255, 0.22)",
                    lineHeight: "initial",
                    background: "rgb(126 46 164 / 15%)",
                    padding: "6px 25px",
                  }}
                >
                  {loading ? "Posting..." : "Post"}
                </Button>
              </Box>
            </form>
          )}
          {commentData &&
            commentData?.map((e, i) => <Message data={e} index={i} />)}
        </Box>
        <Footer />
        <MobileChains />
      </Container>
    </Box>
  );
};

export default NftDetailPage;
