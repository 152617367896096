import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import bsc_icon from "../../Assets/Images/bsc_icon.svg";
import bitcoin from "../../Assets/Images/bitcoin.png";
import create_coin from "../../Assets/Images/create_coin.gif";
import {
  useAccount,
  useBalance,
  useBlockNumber,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { API_URL, POOL_ADDRESS } from "../../Config";
import TOKEN_COIN_ABI from "../../Config/TOKEN_COIN_ABI.json";
import { useParams } from "react-router-dom";
import { formatEther, parseEther, erc20Abi } from "viem";
import { useQueryClient } from "@tanstack/react-query";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ContractContext } from "../../Context/ContractContext";
import HolderDistribution from "./HolderDistribution";
import axios from "axios";

const TradeContainer = ({ tokenData }) => {
  const { id: projectId } = useParams();
  const { coinData,getCrown } = useContext(ContractContext);
  const queryClient = useQueryClient();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const [buyValue, setBuyValue] = useState(0);
  const [buyErr, setBuyErr] = useState("");
  const [show, setShow] = useState(false);
  const [trades, setTrades] = useState([]);
  const [coinTokenSymbol, setCoinTokenSymbol] = useState("TBNB");
  const getHolderDistribution = async () => {
    await axios({
      method: "GET",
      url: `${API_URL}/get/trades/${projectId}`,
      headers: {
        "Accept-Encoding": "application/json",
        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setTrades(_data.data.trades);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  useEffect(()=>{
    getHolderDistribution()
  },[projectId])
  const handleOnchange = (e) => {
    setBuyErr("");
    const value = e.target.value;
    if (value >= 0) {
      setBuyValue(value);
    }
  };
  const [sellValue, setSellValue] = useState(0);
  const [sellErr, setSellErr] = useState("");
  const handleSellOnchange = (e) => {
    setSellErr("");
    const value = e.target.value;
    if (value >= 0) {
      setSellValue(value);
    }
  };
  const handleReset = (e) => {
    setBuyValue(0);
    setSellValue(0);
  };
  const { address, isConnected } = useAccount();
  const { data: balance, queryKey: balanceQueryKey } = useBalance({
    address: address,
  });
  const { data: alternateToken } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "alternateToken",
    args: [],
  });
  const { data: alternateTokenSymbol } = useReadContract({
    abi: erc20Abi,
    address: alternateToken,
    functionName: "symbol",
    args: [],
  });
  useEffect(() => {
    if (alternateTokenSymbol !== undefined && tokenData?.isToken === true) {
      setCoinTokenSymbol(alternateTokenSymbol);
    }
  }, [alternateTokenSymbol]);
  const { data: tokenBalance, queryKey: tokenBalanceQueryKey } =
    useReadContract({
      address: tokenData?.address,
      abi: erc20Abi,
      functionName: "balanceOf",
      args: [address],
    });
    // console.log("tokenBalance",formatEther(tokenBalance));
    
  const {
    data: alternateTokenBalance,
    queryKey: alternateTokenBalanceQueryKey,
  } = useReadContract({
    address: alternateToken,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [address],
  });

  const { data: allowance, queryKey: allowanceQueryKey } = useReadContract({
    abi: erc20Abi,
    address: tokenData?.address,
    functionName: "allowance",
    args: [address, POOL_ADDRESS],
  });
  const {
    data: alternateTokenAllowance,
    queryKey: alternateTokenAllowanceQueryKey,
  } = useReadContract({
    abi: erc20Abi,
    address: alternateToken,
    functionName: "allowance",
    args: [address, POOL_ADDRESS],
  });
  const { data: projects } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "projects",
    args: [projectId],
  });
  const { data: getTokensForAmount } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getPrice",
    args: [projectId,projects?.[7]],
  }); 
  

  const { data: getSellPrice } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getSellPrice",
    args: [projectId],
  });


 

  
  const { data: getBuyFee } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getBuyFee",
    args: [parseEther(buyValue.toString()),projects?.[7]],
  });

  const { data: getSellFee } = useReadContract({
    abi: TOKEN_COIN_ABI,
    address: POOL_ADDRESS,
    functionName: "getSellFee",
    args: [parseEther(sellValue.toString()),projects?.[7]],
  });


  useEffect(() => {
    if (
      balanceQueryKey ||
      tokenBalanceQueryKey ||
      allowanceQueryKey ||
      alternateTokenBalanceQueryKey ||
      alternateTokenAllowanceQueryKey
    ) {
      queryClient.invalidateQueries({ queryKey: balanceQueryKey });
      queryClient.invalidateQueries({ queryKey: tokenBalanceQueryKey });
      queryClient.invalidateQueries({ queryKey: allowanceQueryKey });
      queryClient.invalidateQueries({
        queryKey: alternateTokenAllowanceQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: alternateTokenBalanceQueryKey,
      });
    }
  }, [
    blockNumber,
    queryClient,
    balanceQueryKey,
    tokenBalanceQueryKey,
    allowanceQueryKey,
    alternateTokenAllowanceQueryKey,
    alternateTokenBalanceQueryKey,
  ]);
  const { writeContractAsync } = useWriteContract();
  const [approveTxn, setApproveTxn] = useState("");
  const [alternateApproveTxn, setAlternateApproveTxn] = useState("");
  const [approveIsLoading, setApproveIsloading] = useState("");
  const [alternateApproveIsLoading, setAlternateApproveIsloading] =
    useState("");
  const [buyTxn, setBuyTxn] = useState("");
  const [buyIsLoading, setBuyIsloading] = useState("");
  const [sellTxn, setSellTxn] = useState("");
  const [sellIsLoading, setSellIsloading] = useState("");

  const buyTokenWriteAsync = async () => {
    if (buyValue === "") {
      setBuyErr("Please enter value");
      return;
    }
    console.log(
      buyValue >
        formatEther(
          tokenData?.isToken == true ? alternateTokenBalance : balance?.value
        )
    );
    if (
      buyValue >
      formatEther(
        tokenData?.isToken == true ? alternateTokenBalance : balance?.value
      )
    ) {
      setBuyErr("Insufficient balance");
      return;
    }
    const config = {
      address: POOL_ADDRESS,
      abi: TOKEN_COIN_ABI,
      functionName: "buyTokens",
      args: [projectId, parseEther(buyValue.toString())],
      value: projects?.[7] === false ? parseEther(buyValue.toString()) + getBuyFee : 0,
    };
    setBuyTxn(null);
    try {
      setBuyIsloading(true);
      const txn = await writeContractAsync?.(config);
      setBuyTxn(txn);
    } catch (e) {
      setBuyIsloading(false);
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingBuyWait,
    isSuccess: isSuccessBuyWait,
    data: buyToken,
  } = useWaitForTransactionReceipt({
    hash: buyTxn,
  });

  const sellTokenWriteAsync = async () => {
    if (sellValue === "") {
      setSellErr("Please enter value");
      return;
    }
    if (sellValue > formatEther(tokenBalance || 0)) {
      setSellErr("Insufficient token balance");
      return;
    }
    const config = {
      address: POOL_ADDRESS,
      abi: TOKEN_COIN_ABI,
      functionName: "sellTokens",
      args: [projectId, parseEther(sellValue?.toString())],
      value: getSellFee
    };
    setSellTxn(null);
    try {
      setSellIsloading(true);
      const txn = await writeContractAsync?.(config);
      setSellTxn(txn);
    } catch (e) {
      setSellIsloading(false);
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingSellWait,
    isSuccess: isSuccessSellWait,
    data: sellToken,
  } = useWaitForTransactionReceipt({
    hash: sellTxn,
  });
  useEffect(() => {
    if (isSuccessSellWait || isSuccessBuyWait) {
      setBuyValue(0);
      setSellValue(0);
      getCrown()
    }
  }, [isSuccessSellWait, isSuccessBuyWait]);
  const approveWriteAsync = async () => {
    const approveAmount = "1000000000000000000000";
    const config = {
      address: tokenData?.address,
      abi: erc20Abi,
      functionName: "approve",
      args: [POOL_ADDRESS, approveAmount.toString()],
    };
    setApproveTxn(null);
    try {
      setApproveIsloading(true);
      const txn = await writeContractAsync?.(config);
      setApproveTxn(txn);
    } catch (e) {
      setApproveIsloading(false);
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingApproveWait,
    isSuccess: isSuccessApproveWait,
    data: approveData,
  } = useWaitForTransactionReceipt({
    hash: approveTxn,
  });
  const alternateApproveWriteAsync = async () => {
    const alternateApproveAmount = "1000000000000000000000";
    const config = {
      address: alternateToken,
      abi: erc20Abi,
      functionName: "approve",
      args: [POOL_ADDRESS, alternateApproveAmount.toString()],
    };
    setAlternateApproveTxn(null);
    try {
      setAlternateApproveIsloading(true);
      const txn = await writeContractAsync?.(config);
      setAlternateApproveTxn(txn);
    } catch (e) {
      setAlternateApproveIsloading(false);
      console.log(e);
    }
  };
  const {
    isLoading: isLoadingAlternateApproveWait,
    isSuccess: isSuccessAlternateApproveWait,
    data: alternateApproveData,
  } = useWaitForTransactionReceipt({
    hash: alternateApproveTxn,
  });

  return (
    <>
      <Box className="trade_box">
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Button
            className={`button buy ${!show ? "active" : ""}`}
            onClick={() => setShow(false)}
          >
            Buy
          </Button>
          <Button
            className={`button sell ${show ? "active" : ""}`}
            onClick={() => setShow(true)}
          >
            Sell
          </Button>
        </Box>
        <Box
          sx={{
            mt: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button className="reset_btn">Swap</Button>

          <Typography sx={{ fontSize: "16px", color: "#CAA9DA" }}>
            {/* Bal: {tokenBalance ?formatEther(parseInt(tokenBalance)):0} {tokenData?.symbol||"TBNB"} */}
            Bal:{" "}
            {show
              ? tokenBalance
                ? formatEther(tokenBalance)
                : 0
              : tokenData?.isToken == true
              ? formatEther(alternateTokenBalance || 0)
              : parseFloat(formatEther(balance?.value || 0)).toFixed(3)}{" "}
            {show
              ? tokenData?.symbol || "TBNB"
              : tokenData?.isToken == true
              ? alternateTokenSymbol
              : balance?.symbol}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "0.2rem", mt: "0.5rem" }}>
          <TextField
            sx={{
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
            }}
            autoComplete="off"
            fullWidth
            id="name"
            name="name"
            type="number"
            value={show ? sellValue : buyValue}
            onChange={show ? handleSellOnchange : handleOnchange}
            placeholder="Enter value"
            variant="standard"
          />
          <Typography
            sx={{
              display: "flex",
              gap: "0.2rem",
              alignItems: "center",
              fontFamily: "latoBold",
              border: "1px solid #6F6F6F",
              p: "5px",
              borderRadius: "3px",
            }}
          >
            <Typography component={"img"} src={bsc_icon} width={"20px"} />
            {show ? tokenData?.symbol : coinTokenSymbol}
            {/* tokenData?.isToken === false
              ? "TBNB"
              : "USDT" */}
          </Typography>
        </Box>
        {buyErr && (
          <Typography
            sx={{
              fontFamily: "lato",
              fontSize: "15px",
              color: "red",
            }}
          >
            {buyErr}
          </Typography>
        )}
        {sellErr && (
          <Typography
            sx={{
              fontFamily: "lato",
              fontSize: "15px",
              color: "red",
            }}
          >
            {sellErr}
          </Typography>
        )}
        <Box
          mt={"0.5rem"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button className="reset_btn" onClick={handleReset}>
            Reset
          </Button>
          {/* {formatEther(getTokensForAmount)} */}
          {show ? (
            <Typography sx={{ fontSize: "16px", color: "#CAA9DA" }}>
              {getTokensForAmount && sellValue != ""
                ? parseFloat(
                    1/parseFloat(parseInt(getTokensForAmount)) *
                      parseFloat(sellValue.toString())
                  ).toFixed(sellValue > 0 && 6)
                : 0}{" "}
              {/* {tokenData?.isToken === false ? "TBNB" : "USDT"} */}
              {coinTokenSymbol}
            </Typography>
          ) : (
            <Typography sx={{ fontSize: "16px", color: "#CAA9DA" }}>
              {/* {getTokensForAmount ? parseInt(getTokensForAmount) : 0} */}
              {getTokensForAmount && buyValue != ""
                ? parseFloat(
                    parseFloat(parseInt(getTokensForAmount)) *
                      parseFloat(buyValue.toString())
                  ).toFixed(buyValue > 0 && 6)
                : 0}{" "}
              {/* {tokenData?.isToken === false ? "TBNB" : "USDT"} */}
              {/* {tokenData?.symbol} */}
              {tokenData?.symbol}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            "& .trade_btn": {
              backgroundImage: `url(${create_coin})`,
              backgroundSize: "cover", // Adjust as needed (contain, cover, etc.)
              backgroundPosition: "100%",
              backgroundRepeat: "no-repeat",
              "&.Mui-disabled": {
                cursor: "not-allowed !important",
                pointerEvents: "auto !important",
                color: "rgb(255 255 255 / 38%) !important",
                "&:hover": {
                  opacity: "1",
                },
              },
            },
          }}
        >
          {isConnected ? (
            show && allowance == 0 ? (
              <Button
                className="trade_btn"
                onClick={() => approveWriteAsync?.()}
              >
                {approveIsLoading && !isSuccessApproveWait
                  ? "Loading.."
                  : `Approve ${tokenData?.symbol}`}
              </Button>
            ) : tokenData?.isToken == true && alternateTokenAllowance == "0" ? (
              <Button
                className="trade_btn"
                onClick={() => alternateApproveWriteAsync?.()}
              >
                {alternateApproveIsLoading && !isSuccessAlternateApproveWait
                  ? "Loading.."
                  : `Approve ${alternateTokenSymbol}`}
              </Button>
            ) : (
              <Button
                className="trade_btn"
                onClick={
                  show
                    ? () => sellTokenWriteAsync()
                    : () => buyTokenWriteAsync()
                }
                disabled={show ? sellValue === 0 : buyValue === 0}
              >
                {show
                  ? sellIsLoading && !isSuccessSellWait
                    ? "Loading.."
                    : "Sell Trade"
                  : buyIsLoading && !isSuccessBuyWait
                  ? "Loading.."
                  : "Buy Trade"}
              </Button>
            )
          ) : (
            <Box
              my="1rem"
              sx={{
                "& button": {
                  width: "100% !important",
                  textAlign: "center !important",
                  border: "1px solid rgb(255 255 255 / 70%) !important",
                  borderRadius: "6px !important",
                },
              }}
            >
              <ConnectButton label="Connect" />
            </Box>
          )}
        </Box>
      </Box>

      {/* Bottom-box */}
     
      <Box
        sx={{
          mt: "1rem",
          background: "rgb(126 46 164/ 42%)",
          borderRadius: "5px",
          p: ".5rem",
          "& p": {
            fontFamily: "latoBold",
          },
          "& span": {
            color: "#C9C9C9",
            fontFamily: "lato",
            fontSize: "14px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "16px", color: "#CAA9DA" }}>
            News
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#909090" }}>
            8hrs ago
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "14px", py: "0.5rem" }}>
          Grayscale Bitcoin ETF Ends 77-days of Outflows, GBTC Inflows Begin
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Typography component={"span"}>
            On Friday, May 3, the Bitcoin price jumped soon after the release of
            the US jobs data...
          </Typography>
          <Typography component={"img"} src={bitcoin} width={"110px"} />
        </Box>
      </Box>

      {trades?.length>0 && <Box
        sx={{
          mt: "1rem",
          background: "rgb(126 46 164/ 42%)",
          borderRadius: "5px",
          p: ".5rem",
        
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between",pb:"0.5rem" }}>
          <Typography sx={{ fontSize: "16px", color: "#CAA9DA",fontWeight:"bold"}}>
            Holder distribution
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#909090" }}>
            {/* 8hrs ago */}
          </Typography>
        </Box>
        {trades?.map((e, i) => (
       <HolderDistribution e={e} i={i} tokenAdd={tokenData?.address}/>
        ))}
      </Box>
    }
    </>
  );
};

export default TradeContainer;
