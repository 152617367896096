import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiProvider, http } from "wagmi";
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  phantomWallet,
  trustWallet,
  metaMaskWallet

} from '@rainbow-me/rainbowkit/wallets';

import {
  RainbowKitProvider,
  getDefaultConfig,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import ContractContextProvider from "./Context/ContractContext";
import { bscTestnet, mainnet } from "viem/chains";
import { createConfig } from 'wagmi';


const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet,walletConnectWallet,phantomWallet,trustWallet],
    },
  ],
  {
    appName: 'My RainbowKit App',
    projectId: 'YOUR_PROJECT_ID',
  }
);

const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains: [mainnet, bscTestnet],
});

// const config = createConfig({
//   chains: [mainnet, bscTestnet],
//   connectors,
//   transports: {
//     [mainnet.id]: http(),
//     [bscTestnet.id]: http(),
//   },
// });
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          theme={darkTheme({
            // accentColor: 'linear-gradient(270deg, #960065 0%, #B651C5 100%)',
            accentColor: "#45125E",
            accentColorForeground: "white",
            borderRadius: "large",
            fontFamily: "latoBold",
          })}
        >
          <ContractContextProvider>
            <App />
          </ContractContextProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
