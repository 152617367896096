
import './App.css';
import AllRoutes from './AllRoutes';
import { Box } from '@mui/material';

function App() {
  return (
    <Box  sx={{
      background:"linear-gradient(138deg, rgb(126 46 164 / 60%) 0%, rgb(0 0 0) 49%, rgb(126 46 164 / 51%) 100%)",
      height:"100vh",
      overflow:"auto",
      "& .MuiContainer-root":{
        px:"10px",
        "@media (min-width:1260px)":{
          maxWidth:"1380px"
        },
      }
      
    }}>
    <AllRoutes/>
    </Box>
  );
}

export default App;
