import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import Phantom_logo from "../Assets/Images/download.svg";
import disconnect from "../Assets/Images/disconnect.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import CopyToClipboard from "react-copy-to-clipboard";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        // fontSize: "1.2rem",
        // p: 1.2,
        // borderBottom: "2px solid rgba(255, 255, 255, 0.22)",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 6,
            top: 6,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const SolanaDisconnectModal = ({
  solanaModal,
  handleSolanaModalClose,
  walletAddress,
  setWalletAddress,
}) => {
  // const connection = new Connection('https://rpc.ankr.com/solana');
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  const isPhantomInstalled = window.phantom?.solana?.isPhantom;
  const [provider, setProvider] = useState(null);
  // const [walletAddress, setWalletAddress] = useState(null);
  const [solBalance, setSolbalance] = useState(0);

  useEffect(() => {
    if ("solana" in window) {
      const anyWindow = window;
      const provider = anyWindow.solana;

      if (provider.isPhantom) {
        setProvider(provider);

        provider
          .connect({ onlyIfTrusted: true })
          .then(({ publicKey }) => {
            // setWalletAddress(publicKey.toString());
            fetchBalance(publicKey);
          })
          .catch((err) => console.error("Connection failed:", err));
      }
    }
  }, []);

  const connectWallet = async () => {
    if (provider) {
      try {
        const { publicKey } = await provider.connect();
        setWalletAddress(publicKey.toString());
      } catch (err) {
        console.error("Connection failed:", err);
      }
    }
  };

  const disconnectWallet = async () => {
    if (provider) {
      await provider.disconnect();
      setWalletAddress(null);
      handleSolanaModalClose();
    }
  };
  const fetchBalance = async (publicKey) => {
    try {
      const balance = await connection.getBalance(new PublicKey(publicKey));
      // alert(balance)
      setSolbalance(balance / 1e9); // Convert lamports to SOL
    } catch (err) {
      // alert(err)
      console.error("Failed to fetch balance:", err);
    }
  };
  return (
    <>
      <Dialog
        onClose={handleSolanaModalClose}
        disableScrollLock
        aria-labelledby="customized-dialog-title"
        open={solanaModal}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.2rem 1rem",
          },
          "& .MuiDialogActions-root": {
            padding: "1rem",
          },
          "& .MuiDialog-container": {
            backdropFilter: "blur(2px)",
          },
          "& .MuiPaper-root": {
            // maxWidth: "90% !important",
            maxWidth: "400px !important",
            background: "#000",
            backgroundImage:
              "linear-gradient(305deg, rgb(38 0 252 / 5%), rgb(255 0 234 / 9%))",
            border: "2px solid rgba(255, 255, 255, 0.22) !important",
            backdropFilter: "blur(12.5px)",
            color: "#fff",
            width: { md: "100% !important", xs: "300px !important" },
            overflowX: { md: "auto", xs: "scroll" },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleSolanaModalClose}
        >
          {/* Connect wallet */}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box textAlign={"center"}>
            {isPhantomInstalled && (
              // <ConnectButton label="Connect" />
              <Box>
                <div>
                  <img src={Phantom_logo} style={{ width: "40px" }} />
                </div>

                {walletAddress && (
                  <>
                    <p style={{ margin: "0" }}>
                      {walletAddress.substr(0, 5)}...
                      {walletAddress.substr(walletAddress.length - 5, 5)}
                    </p>
                    <p style={{ margin: "0", paddingBottom: "1rem" }}>
                      Bal: {solBalance} SOL
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { sm: "space-between", xs: "center" },
                        flexWrap: "wrap",
                        gap: "0.5rem",
                        "& button": {
                          color: "#fff",
                          flexDirection: "column",
                          border: "1px solid #7E2EA4",
                          background: "#45125E",
                          p: "0.4rem 1.5rem",
                          width: { sm: "150px", xs: "60%" },
                          textTransform: "capitalize",
                          borderRadius: "6px",
                          fontSize: "15px",
                          "&:hover": {
                            background: "#632283",
                          },
                          textTransform: "capitalize",
                        },
                        "& svg": {
                          fontSize: "1.2rem",
                        },
                      }}
                    >
                      <CopyToClipboard text={walletAddress} onCopy={onCopyText}>
                        <Button>
                          {isCopied ? (
                            <>
                              <DoneIcon />
                              Copied
                            </>
                          ) : (
                            <>
                              <ContentCopyIcon />
                              Copy Address
                            </>
                          )}
                        </Button>
                      </CopyToClipboard>
                      <Button onClick={() => disconnectWallet()}>
                        <img src={disconnect} />
                        Disconnect
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SolanaDisconnectModal;
