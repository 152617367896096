import { Badge, Box, Button, Collapse, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import pump_fun_logo from "../Assets/Images/pump_fun_logo.png";
import create_coin from "../Assets/Images/create_coin.gif";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../Config";
import axios from "axios";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import twitter from "../Assets/Images/twitter.svg";
import TelegramIcon from '@mui/icons-material/Telegram';
import Base from "../Assets/Images/Base.svg";
import BSC from "../Assets/Images/BSC.svg";
import Solana from "../Assets/Images/Solana.svg";
import ton from "../Assets/Images/ton.svg";

const MobileNav = ({ handleDrawerToggle,cartValue }) => {
  const {address,isConnected} = useAccount()
  const createUser = async () => {
    try {
      const res = await axios.post(`${API_URL}/create/user`, {
        address: address,
      });
      if (res.status === 201) {
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("username",res.data.data.username);
      }
      if (res.status === 200) {
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("username",res.data.data.username);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 404) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (isConnected && address !== undefined) {
      createUser();
    }
  }, [isConnected, address]);
  useEffect(() => {
    if (!isConnected) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
    }
  }, [isConnected]);
  return (
    <Box sx={{
  "& .submenuList": {
    padding: "0.4rem",
    borderRadius: "4px",
    fontFamily: "latoBold !important",
    "&:hover": {
      color: "#800080",
    },
  },
    }}>
         <Typography component={"img"} src={pump_fun_logo} 
              sx={{
                filter:"hue-rotate(45deg)",
                width:{sm:"70px",xs:"70px"}
              }}
            />
      <IconButton
        aria-label="close"
        onClick={handleDrawerToggle}
        title="Close"
        sx={{
          position: "absolute",
          right: "5px",
          color: "#fff",
          top: "5px",
          // background: "#fff",
          "&:hover": {
            // background: "#fff",
          },
          // color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: "1.5rem" }} />
      </IconButton>
      <Box
        // onClick={handleDrawerToggle}
        sx={{
          // textAlign: "center",
          display: "grid",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.1rem",
          mt: "2rem",
          "& a": {
            // color: "#800080",
            color: "#fff",
            textDecoration: "none",
          },
          "& a:hover": {
                      color: "#fff",
                    },
        }}
      >
        <Typography className="submenuList" textAlign={"center"}>
          <Link to={"/"}>Home</Link>
       </Typography>
        {/* <Typography className="submenuList">
          <Link to={"/contact"}>Contact</Link>
       </Typography> */}

              <Box
                sx={{
                  "& img": {
                    border: "0.5px solid transparent",
                    cursor: "pointer",
                    transition: "0.3s all ease",
                    "&:hover": {
                      border: "0.5px solid #a632dd99",
                      borderRadius: "3px",
                    },
                  },
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "17px",
                    color: "rgba(255, 255, 255, 0.7)",
                    fontFamily: "latoBold",
                    pb: "1rem",
                  }}
                >
                  Supported Chains
                </Typography> */}
                <Box sx={{
                  display:"flex",
                  alignItems:"center",
                  gap:"0.2rem",
                  justifyContent:"center",
                  flexWrap:"wrap"
                }}>
                  <Typography component={"img"} src={Base} width={"100px"}/>
                  {/* <Typography component={"img"} src={Blast} /> */}
                  <Typography component={"img"} src={BSC} />
                  <Typography component={"img"} src={Solana} />
                  <Typography component={"img"} src={ton}  width={"90px"} p={"0.2rem"}/>
                </Box>
              </Box>
        
       <Button
                component={Link}
                to="/create-coin"
                sx={{
                  mt:"1rem",
                  backgroundImage: `url(${create_coin})`,
                  backgroundSize: "100% 100%", // Adjust as needed (contain, cover, etc.)
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  // background: "rgb(140,27,142)",
                  // background:
                  //   "linear-gradient(135deg, rgba(140,27,142,1) 0%, rgba(150,0,63,1) 24%, rgba(79,7,113,1) 100%)",
                  fontSize: "16px",
                  color: "rgba(255, 255, 255, 0.7)",
                  fontFamily: "latoBold",
                  transition: "all 0.3s ease",
                  // backgroundSize: "200% 200%",
                  textTransform: "capitalize",
                  width:"100%",
                  p: "5px",
                }}
              >
                Create a fresh coin!
              </Button>
              <Box
        sx={{
          pt: "1rem",
          display: "flex",
          justifyContent:"center",
          gap: "0.7rem",
          alignItems: "center",
          "& img,svg": {
            transition: "0.3s all",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.2)",
            },
          },
          "& a": {
            color: "#fff",
            lineHeight: "0",
          },
          "& svg": {
            fontSize:"1.8rem",
            color:"#caa9da"
          },
        }}
      >
        {/* <Typography component={"img"} src={fb} />
        <Typography component={"img"} src={discord} /> */}
        <Typography component={"img"} src={twitter} width={"20px"}/>
        {/* <Typography component={"img"} src={insta} /> */}
        <TelegramIcon/>
      </Box>
      {/* {!isConnected && <Box
              my="0.5rem"
              sx={{
                "& button": {
                  width: "100% !important",
                  textAlign: "center !important",
                  border: "1px solid rgb(255 255 255 / 70%) !important",
                  borderRadius: "6px !important",
                  fontFamily:"latoBold",
                  py:"10px",
                  background:"#45125E",
                  color:"#fff"
                },
              }}
            >
              <ConnectButton label="Connect" />
            </Box>} */}
    
      </Box>
    </Box>
  );
};

export default MobileNav;
