import { Box, Typography,Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import heart from "../Assets/Images/heart.svg";
import crown from "../Assets/Images/crown.png";
import TimelineIcon from "@mui/icons-material/Timeline";


const CrownBox = ({crownData}) => {

  return (
    <Box
    sx={{
      my: "2rem",
      border:"2px dashed #7E2EA4",
      borderRadius:"10px",
      p:"15px",
      background:"rgb(255 255 255 / 7%)"
    }}
  >

    <Typography
      sx={{
        // position: "absolute",
        // top: "35px",
        // right: "35px",
        fontSize: "35px",
        textAlign:"center",
        lineHeight: { md: "1.2", xs: "1.2" },
        fontFamily: "MacondoSwashCaps-Regular",
        color: "#F5EE36",
        textShadow: "0 0.1em 10px red", // Initial shadow effect
        animation: `blooming 2s infinite`,
        "@keyframes blooming": {
          "0%": {
            textShadow: "0 0.1em 10px red", // Initial shadow
          },
          "50%": {
            textShadow:
              "0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red",
          },
          "100%": {
            textShadow: "0 0.1em 10px red", // Return to initial shadow
          },
        },
      }}
    >
      Look who’s wearing the crown now!
    </Typography>
    <Box
    //   sx={{
    //     width: "72%",
    //     position: "absolute",
    //     bottom: "50%",
    //     transform: "translateY(100%)",
    //     right: "0",
    //   }}
    >
      <Grid
      mt={"1rem"}
        container
        spacing={2}
        sx={{
            justifyContent:"center",
            textAlign:"center",
          "& .text": {
            color: "#CAA9DA",
            fontSize: "20px",
            fontFamily: "lato",
          },
          "& .span_text": {
            color: "#fff",
            fontSize: "22px",
            fontFamily: "latoBold",
          },
        }}
      >
          <Box sx={{
            textAlign:"center",
            mb:"0.5rem",
       }}>
       <Typography
          component={"img"}
          src={crown}
          sx={{
            width: "40px",
            height: "40px",
            pb:"0.3rem"
          }}
        />
       <Typography
          component={"img"}
          src={crownData?.coinsData?.image}
          sx={{
            display:"block",
            width: "120px",
            height: "150px",
            borderRadius:"5px"
          }}
        />
        </Box>
        <Grid item lg={6} xs={12}>
          <Typography className="text">
            Founded by:{" "}
            <Typography
              component={"img"}
              src={heart}
              sx={{
                width: "23px",
                height: "23px",
              }}
            />{" "}
            <span className="span_text" style={{textTransform:"capitalize"}}>   {crownData?.username?.length>8
    ? `${crownData?.username.slice(0, 4)}...${crownData?.username.slice(-4)}`
    : crownData?.username}</span>
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className="text">
            Opinions: <span className="span_text">{crownData?.comments}</span>
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className="text">
            value: <span className="span_text">$ {crownData?.coinsData?.marketValue>0?parseFloat(crownData?.coinsData?.marketValue).toFixed(2):0}</span>{" "}
            <span className="span_text" style={{ color: "#7E2EA4" }}>
              <TimelineIcon
                sx={{ fontSize: "3rem", verticalAlign: "middle" }}
              />
            </span>
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className="text">
            Code:{" "}
            <span className="span_text" style={{ color: "#7E2EA4" }}>
            {crownData?.coinsData?.symbol?.length>8
    ? `${crownData?.coinsData?.symbol.slice(0, 4)}...${crownData?.coinsData?.symbol.slice(-4)}`
    : crownData?.coinsData?.symbol}
            </span>{" "}
            <span style={{ color: "#fff", fontSize: "18px" }}>
              ( {crownData?.coinsData?.name} )
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Box>
  )
}

export default CrownBox