import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React, { useContext } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import bottom from "../Assets/Images/bottom.svg";
import left from "../Assets/Images/left_arrow.gif";
import right from "../Assets/Images/right_arrow.gif";
import CreateCoinForm from "./CreateCoinForm";
import coin_image from "../Assets/Images/coin_image.png";
import Property from "../Assets/Images/Property.png";
import CustomButton from "../Components/CustomButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import MobileChains from "../Components/MobileChains";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import SolanaWalletConnect from "../Components/SolanaWalletConnect";
import { ContractContext } from "../Context/ContractContext";

const CreateCoin = () => {
  const { isConnected } = useAccount();
  const {selectedChain} = useContext(ContractContext)
  return (
    <Box
      sx={{
        position: "relative",
        // overflow: "hidden",
      }}
    >
      <Typography
        component={"img"}
        src={Property}
        sx={{
          position: "absolute",
          top: "45%",
          transform: "translate(0%, -45%)",
          width: "100%",
          //             animation: "wave 11s linear infinite",
          //             "@keyframes wave": {
          //     "0%": {
          //       transform: "translateX(100%)"
          //     },
          //     "100%": {
          //       transform: "translateX(-100%)"
          //     }
          //   }
        }}
      />
      <Container>
        <Navbar />
        {selectedChain === 1 ? (
          <SolanaWalletConnect />
        ) : (
          <>
            {isConnected && (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: "0.5rem",
                }}
              >
                <ConnectButton label="Connect" />
              </Grid>
            )}
          </>
        )}
        <Button
          sx={{
            mt: "1rem",
            border: "1px solid rgb(255 255 255 / 50%)",
            background: "transparent",
            color: "rgb(255 255 255 / 50%)",
            p: "0.3rem 0.7rem",
            fontSize: "16px",
            textTransform: "capitalize",
            fontFamily: "latoBold",
            "&:hover": {
              background: "transparent",
            },
          }}
          component={Link}
          to="/"
          variant="contained"
          tabIndex={-1}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Back
        </Button>
        <Box
          sx={{
            color: "#fff",
            mt: "2rem",
            position: "relative",
            "& .heading": {
              fontSize: "50px",
              fontFamily: "latoBold",
              textAlign: "center",
              "@media (max-width:600px)": {
                lineHeight: "1.5",
                fontSize: "30px",
              },
            },
            "& .title": {
              fontSize: "60px",
              fontFamily: "lato-black",
              textAlign: "center",
              "@media (max-width:600px)": {
                fontSize: "40px",
              },
            },
            "& .textColor": {
              color: "#7E2EA4",
            },
            "& .img": {
              width: "35px",
              "@media (max-width:600px)": {
                width: "32px",
              },
            },
          }}
          data-aos="fade-up"
        >
          <Typography
            component={"img"}
            src={bottom}
            sx={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translate(-50%, -20%)",
              // width: "100%",
            }}
          />
          <Box position={"relative"}>
            <Typography className="heading">Turn Your Concept Into</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                gap: "1rem",
              }}
            >
              <Box lineHeight={0}>
                <Typography component={"img"} src={left} className="img" />
              </Box>
              <Typography className="title">Currency</Typography>
              <Box lineHeight={0}>
                <Typography component={"img"} src={right} className="img" />
              </Box>
            </Box>
            <Typography className="heading textColor awesome">
              Create Your Coin!
            </Typography>
            <CreateCoinForm />
          </Box>
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(306deg, rgba(126,46,164,0.5) 0%, rgba(75,29,97,0.1) 48%, rgba(126,46,164,0.5) 100%)",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Typography
            component={"img"}
            src={bottom}
            sx={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translate(-40%, -20%)",
              // width: "100%",
            }}
          />
          <Grid container spacing={3} position={"relative"} mt="4rem">
            <Grid item md={4} sm={12} xs={12}>
              <Typography
                component={"img"}
                src={coin_image}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Box
                sx={{
                  p: "20px",
                  "& .textColor": {
                    color: "#7E2EA4 !important",
                  },
                  "& .text": {
                    fontSize: "24px",
                    fontFamily: "lato",
                    color: "#fff",
                  },
                  "& .MuiFormControl-root": {
                    width: "100%",
                    border: "1px solid #7E2EA4 !important",
                    borderRadius: "5px",
                    background: "transparent",
                    color: "#fff",
                    "&:focus-Visible": {
                      outline: "none !important",
                      border: "none !important",
                    },
                    "&:hover": {
                      border: "none",
                    },

                    "&:focus": {
                      boxShadow: "none !important",
                      border: "2px solid #222222 !important",
                      borderRadius: "0px !important ",
                    },
                    "& .MuiInputBase-root": {
                      "&::before": {
                        display: "none",
                      },
                      "&::after": {
                        display: "none",
                      },
                    },
                    "& input": {
                      padding: "8px",
                      fontSize: "20px",
                      borderRadius: "10px",
                      fontWeight: "500",
                      fontFamily: "lato !important",
                      color: "rgb(255 255 255 / 80%)",
                      "&::placeholder": {
                        color: "#fff !important",
                        fontFamily: "lato",
                        fontSize: "16px",
                      },
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "55px", xs: "30px" },
                    fontFamily: "latoBold",
                    background:
                      "linear-gradient(to bottom, #FFFFFF 0%, #A855F7 50%, #800080 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    pb: { md: "4rem", xs: "2rem" },
                  }}
                >
                  Be a Pro!!
                </Typography>
                <Typography
                  className="text"
                  sx={{
                    width: { lg: "85%", xs: "100%" },
                  }}
                >
                  Create your coin now and join our founder’s special program to
                  receive a{" "}
                  <span className="textColor">free consultation session</span>{" "}
                  with our cryptocurrency experts.
                </Typography>
                <Typography
                  sx={{
                    width: { lg: "85%", xs: "100%" },
                    py: "1.5rem",
                    fontSize: "20px",
                    fontFamily: "lato",
                    color: "#B5B5B5",
                  }}
                >
                  Take advantage of this limitied time offer to kickstart your
                  coin creation journey.
                </Typography>
                <Box
                  sx={{
                    pt: "1rem",
                    display: "flex",
                    justifyContent: { sm: "space-between", xs: "center" },
                    gap: "1rem",
                    flexDirection: { md: "row", xs: "column" },
                  }}
                >
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    // className={classes.bin1}
                    placeholder="Enter your email"
                    variant="standard"
                    // value={formik.values.description}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                  />
                  <CustomButton
                    type={"submit"}
                    text="Enroll now!"
                    width={"50%"}
                    background="#7E2EA4"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Container>
      <MobileChains />
    </Box>
  );
};

export default CreateCoin;
